import React, { useContext, useMemo } from 'react';
import context from '../context';
import styled from 'styled-components/macro';
import Button from './button';

export default React.memo(() => {
    const { state, dispatch } = useContext(context);
    const { selectedPL, innerData, editModal } = state;
    // console.log(state);
    const disabled = selectedPL.length === 0 || editModal.show;

    const onButtonClick = (selectedPlId, typeOfRouter) => e => {
        dispatch({
            type: 'onAddButtonClick',
            payload: { selectedPlId, typeOfRouter }
        });
    };

    return (
        <Footer>
            <Button width="48%" disabled={disabled} onClick={onButtonClick(selectedPL[0], 'adSpaces')}>
                Add AdSpace
            </Button>
            <Button width="48%" disabled={disabled} onClick={onButtonClick(selectedPL[0], 'iPoints')}>
                Add iPoint
            </Button>
        </Footer>
    );
});

const Footer = React.memo(styled.div`
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0;
    margin-top: auto;
    list-style: none;
    justify-content: space-between;
`);
