import React, { useContext, useMemo } from 'react';
import context from '../../context';
import styled from 'styled-components/macro';
import EyeIcon from '../eye_icon';
import ListItem from '../list_item';
import ListItemText from '../list_item_text';
import ColorDot from '../color_dot';

const PlDetails = React.memo(({ ...props }) => {
  const { state, dispatch } = useContext(context);
  const { projectLocationsByPlType, changePlRadius, plOpacity } = state;

  const onPlDetailsEyeClick = plType => e => {
    dispatch({
      type: 'onPlDetailsEyeClick',
      payload: { plType }
    });
  };

  const details = Object.keys(projectLocationsByPlType).map((plType, i) => {
    const isVisible = projectLocationsByPlType[plType].visible;
    return (
      <ListItem key={`${plType} - ${i}`}>
        <ColorDot color={projectLocationsByPlType[plType].color}></ColorDot>
        <ListItemText>{`${plType} (${projectLocationsByPlType[plType].number})`}</ListItemText>
        <EyeIcon isVisible={isVisible} onClick={onPlDetailsEyeClick(plType)} />
      </ListItem>
    );
  });

  const handleChangePlRadius = e => {
    dispatch({
      type: 'handleChangePlRadius',
      payload: e.target.checked
    });
  };

  const handleChangePlOpacity = e => {
    dispatch({
      type: 'handleChangePlOpacity',
      payload: e.target.value
    });
  };

  return (
    <Container>
      <Options>
        <Label direction={'row'}>
          <Input type={'checkbox'} checked={changePlRadius} onChange={handleChangePlRadius}></Input>
          Change Project Locations Radius
        </Label>
        <Label>
          <span>{`Change Project Locations Opacity (${plOpacity})`}</span>
          <Input
            type={'range'}
            min={'0.5'}
            max={'1'}
            value={plOpacity}
            onChange={handleChangePlOpacity}
            step={'0.1'}
          />
        </Label>
      </Options>
      {details}
    </Container>
  );
});

export default PlDetails;

const Container = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Options = styled.div`
  width: 100%;
  padding: 0 10px;
  border: 1px solid #777;
  border-radius: 10px;
  margin-bottom: 10px;

`;

const Input = React.memo(styled.input.attrs(({ type, ...props }) => {
  let marginR = 0;
  let width = '100%';
  if (type === 'checkbox') {
    marginR = '10px';
    width = 'auto';
  }
  return {
    marginR,
    width
  };
})`
  padding: 5px;
  padding-left: 0;
  margin: 0;
  margin-right: ${p => p.marginR};
  font-size: 14px;
  display: flex;
  flex-direction: column;
  width: ${p => p.width} !important;
  min-height: 30px;
  border: none !important;
  border-bottom: 1px solid #1e90ff !important;
  background-color: transparent !important;
  :focus {
    outline: none !important;
  }
`);

const Label = React.memo(styled.label.attrs(props => {
  return {
    direction: !props.direction ? 'column' : props.direction,
    align: !props.direction ? 'flex-start' : 'center',
    cursor: !props.direction ? 'auto' : 'pointer'
  };
})`
  cursor: ${p => p.cursor};
  margin-top: 15px;
  font-size: 14px;
  color: #1e90ff;
  display: flex;
  flex-direction: ${p => p.direction};
  align-items: ${p => p.align};
  width: 100%;
  /* border: 1px solid #777; */
  border-radius: 10px;
  user-select: none;
  span {
    margin-bottom: 5px;
  }
`);
