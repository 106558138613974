import React, { useContext, useMemo, useCallback, useEffect, useState } from 'react';
import context from '../../context';
import { GlobalContext } from '../../../../App';
import styled, { keyframes } from 'styled-components/macro';
import axios from 'axios';
import { postData, putData, getData } from '../../API';
import Button from '../button';
import UpdateRegionsGroup from './update_regions_group';

const EditModal = React.memo(() => {
  const { state, dispatch } = useContext(context);
  const { token } = useContext(GlobalContext);
  const [OKPressed, setOKPressed] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const { groupToEdit, regionGroups, polygonToCreate, layers } = state;

  const showEditModal = !layers.filter(layer => layer.type === 'project_locations')[0].active;
  const showRegionGroupsModal = layers.filter(layer => layer.type === 'region_groups')[0].active;

  const group = useMemo(() => {
    if (!groupToEdit) return null;
    switch (groupToEdit.layerType) {
      case 'region_groups':
        const group = regionGroups.filter(item => item.id === groupToEdit.groupId)[0];
        return group;

      default:
        return null;
    }
  }, [groupToEdit]);

  useEffect(() => {
    setSelectedGroup(() => group);
  }, [group]);

  const toggleOverlayModal = (show, options) => e => {
    e.stopPropagation();
    dispatch({
      type: 'toggleOverlayModal',
      payload: { show, options }
    });
  };

  const onOKClick = e => {
    setOKPressed(true);
    setTimeout(() => {
      setOKPressed(false);
    }, 100);
  };

  const saveNewRegionToServer = mode => async () => {
    const data = {
      region_name: polygonToCreate.region_name,
      region_group_id: polygonToCreate.region_group_id,
      population_name: polygonToCreate.population_name,
      population_number: polygonToCreate.population_number,
      region_geometry: polygonToCreate.region_geometry
    };

    let response;

    if (mode === 'create') {
      const url = 'https://outdoor-maps.getshopster.net/regions/create_region_with_population/';
      response = await postData(token, url, data, dispatch);
    } else if (mode === 'edit') {
      const url = `https://outdoor-maps.getshopster.net/regions/update_region/${polygonToCreate.region_id}/`;
      response = await putData(token, url, data, dispatch);
    }

    if (response.success) {
      dispatch({
        type: 'resetPolygonToCreate'
      });
      getData(
        token,
        `https://outdoor-maps.getshopster.net/regions/region_groups/`,
        'getRegionGroups',
        dispatch
      );
    } else {
      console.log(response.error);
    }
  };

  const saveNewMallToServer = mode => async () => {
    const data = {
      name: polygonToCreate.region_name,
      geometry: polygonToCreate.region_geometry
    };

    let response;

    if (mode === 'create') {
      const url = 'https://outdoor-maps.getshopster.net/malls/create_mall/';
      response = await postData(token, url, data, dispatch);
    } else if (mode === 'edit') {
      const url = `https://outdoor-maps.getshopster.net/malls/update_mall/${polygonToCreate.region_id}/`;
      response = await putData(token, url, data, dispatch);
    }

    if (response.success) {
      dispatch({
        type: 'resetPolygonToCreate'
      });
      getData(token, `https://outdoor-maps.getshopster.net/malls/malls/`, 'getMalls', dispatch);
    } else {
      console.log(response.error);
    }
  };

  const onCreateNewRegionClick = e => {
    dispatch({
      type: 'onCreatePolygon',
      payload: { mode: 'create', layerType: 'region_groups', region_group_id: selectedGroup.id }
    });
  };

  // console.log(selectedGroup);

  const changeNewPolygonParams = field => e => {
    let value = e.target.value;
    if (field === 'population_number') {
      value = Number(value);
    }
    dispatch({ type: 'changeNewPolygonParams', payload: { field, value } });
  };

  const createNewRegionDisabled =
    !polygonToCreate ||
    !polygonToCreate.region_geometry ||
    !polygonToCreate.region_name ||
    !polygonToCreate.population_name ||
    !polygonToCreate.population_number;

  const createNewMallDisabled =
    !polygonToCreate || !polygonToCreate.region_geometry || !polygonToCreate.region_name;

  const editPolygon = type => {
    switch (type) {
      case 'region_groups':
        return (
          <Container show={polygonToCreate}>
            <Title>{polygonToCreate.mode === 'create' ? `Create New Region` : 'Edit Region'}</Title>
            <Title>{`${polygonToCreate.region_name || ''}`}</Title>

            <Label>
              Region Name
              <Input
                type={'text'}
                value={polygonToCreate.region_name || ''}
                onChange={changeNewPolygonParams('region_name')}
              />
            </Label>

            <Label>
              Population Name
              <Input
                type={'text'}
                value={polygonToCreate.population_name || ''}
                onChange={changeNewPolygonParams('population_name')}
              />
            </Label>

            <Label>
              Population Number
              <Input
                type={'number'}
                value={polygonToCreate.population_number || 0}
                onChange={changeNewPolygonParams('population_number')}
              />
            </Label>

            <Button
              disabled={createNewRegionDisabled}
              onClick={saveNewRegionToServer(polygonToCreate.mode)}
              width={'100%'}
              margin={'10px 0'}
            >
              {polygonToCreate.mode === 'create' ? `Create New Region` : 'Update Region'}
            </Button>
            <Button
              onClick={toggleOverlayModal(true, {
                type: 'delete_region_with_population',
                title: 'Delete Region',
                region_id: polygonToCreate.region_id,
                region_name: polygonToCreate.region_name
              })}
              width={'100%'}
              theme={'red'}
            >
              Delete Region
            </Button>
            <Button
              onClick={() =>
                dispatch({
                  type: 'resetPolygonToCreate'
                })
              }
              width={'100%'}
              margin={'10px 0'}
            >
              Cancel
            </Button>
          </Container>
        );

      case 'malls':
        return (
          <Container show={polygonToCreate}>
            <Title>{polygonToCreate.mode === 'create' ? `Create New Mall` : 'Edit Mall'}</Title>
            <Title>{`${polygonToCreate.region_name || ''}`}</Title>

            <Label>
              Mall Name
              <Input
                type={'text'}
                value={polygonToCreate.region_name || ''}
                onChange={changeNewPolygonParams('region_name')}
              />
            </Label>

            <Button
              disabled={createNewMallDisabled}
              onClick={saveNewMallToServer(polygonToCreate.mode)}
              width={'100%'}
              margin={'10px 0'}
            >
              {polygonToCreate.mode === 'create' ? `Create New Mall` : 'Update Mall'}
            </Button>
            <Button
              onClick={toggleOverlayModal(true, {
                type: 'delete_mall',
                title: 'Delete Mall',
                id: polygonToCreate.region_id,
                name: polygonToCreate.region_name
              })}
              width={'100%'}
              theme={'red'}
            >
              Delete Mall
            </Button>
            <Button
              onClick={() =>
                dispatch({
                  type: 'resetPolygonToCreate'
                })
              }
              width={'100%'}
              margin={'10px 0'}
            >
              Cancel
            </Button>
          </Container>
        );

      default:
        return null;
    }
  };

  return showEditModal && polygonToCreate ? (
    editPolygon(polygonToCreate.layerType)
  ) : showRegionGroupsModal && selectedGroup ? (
    <Container show={selectedGroup}>
      <Title>{`Group`}</Title>
      <Title>{`${selectedGroup.name}`}</Title>
      <UpdateRegionsGroup OKPressed={OKPressed} selectedGroup={selectedGroup} />

      <Button onClick={onCreateNewRegionClick} width={'100%'} margin={'10px 0'}>
        Create New Region
      </Button>

      <Button onClick={onOKClick} width={'100%'} margin={'10px 0'}>
        Update Group
      </Button>

      <Button
        onClick={toggleOverlayModal(true, {
          type: 'delete_region_group',
          title: 'Delete Regions Group',
          groupId: selectedGroup.id
        })}
        width={'100%'}
        theme={'red'}
      >
        Delete Group
      </Button>
    </Container>
  ) : null;
});

export default EditModal;

const appearence = keyframes`
  0% { opacity: 0; margin-right: -300px; }
  100% { opacity: 1; margin-right: 0; }
`;

const opacity = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

const CursorCoords = styled.div`
  margin-top: 10px;
  font-size: 18px;
`;

const Warning = styled.span`
  display: ${p => (p.showWarning ? 'flex' : 'none')};
  width: 100%;
  justify-content: center;
  padding: 20px;
  animation: ${opacity} 1s ease-in-out infinite;
  color: red;
`;

const Container = React.memo(styled.div`
    box-sizing: border-box;
    width: 300px;
    height: 100%;
    padding: 10px;
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
    background-color: #ddd;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    /* animation: ${appearence} 0.3s ease-in-out; */
`);

const Title = React.memo(styled.h4`
  padding: 0;
  margin: 0;
  list-style: none;
  /* font-family: HelveticaLight; */
  font-size: 18px;
  color: #1e90ff;
`);

const Buttons = React.memo(styled.div`
  width: 100%;
  display: flex;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  list-style: none;
  justify-content: space-between;
`);

const List = React.memo(styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`);

const Item = React.memo(styled.li`
  padding: 5px 0;
  margin: 0;
  list-style: none;
`);

const Label = React.memo(styled.label`
  padding: 10px 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`);

const Input = React.memo(styled.input`
  width: 100%;
  padding: 5px;
  margin: 0;
  margin-top: 5px;
  display: flex;
  font-size: 16px;
  outline: none;
  border: 1px solid #1e90ff;
  border-radius: 5px;
  pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
  background-color: ${p => (p.disabled ? '#ccc' : '#fff')};
  color: ${p => (p.disabled ? '#999' : '#000')};
`);

const Select = styled.select`
  margin: 0;
  margin-top: 5px;
  display: flex;
  font-size: 16px;
`;

const Option = styled.option`
  padding: 5px;
  margin: 0;
  margin-top: 5px;
  display: flex;
`;
