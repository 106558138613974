import React, { useContext, useMemo, useCallback, useEffect } from 'react';
import context from '../context';
import { GlobalContext } from '../../../App';
import styled, { keyframes } from 'styled-components/macro';
import axios from 'axios';
import Button from './button';

const EditModal = React.memo(() => {
    const { state, dispatch } = useContext(context);
    const { token } = useContext(GlobalContext);
    const {
        innerData,
        editModal,
        allowEditCatalog,
        spacesTypesCatalog,
        saveStatus,
        isPickCursorCoords,
        cursorCoords,
        addNew
    } = state;

    const inputsMap = useMemo(() => {
        const { typeOfRouter, id } = editModal;
        const pointsCatalog = innerData[typeOfRouter] || [];
        const point = pointsCatalog.filter(p => p.id === id)[0];

        return Object.keys(allowEditCatalog[typeOfRouter] || []).reduce(
            (acc, fieldName) => ({
                ...acc,
                [fieldName]: e => {
                    // e.preventDefault();
                    let value = e.target.value;
                    let checked = e.target.checked;
                    if (!editModal.selectedPlId) {
                        dispatch({
                            type: 'handleInputChange',
                            payload: { point, typeOfRouter, field: fieldName, value, checked }
                        });
                    } else {
                        dispatch({
                            type: 'handleAddNewInputChange',
                            payload: { point, typeOfRouter, field: fieldName, value, checked }
                        });
                    }
                }
            }),
            {}
        );
    }, [innerData, editModal, allowEditCatalog]);

    const onSelectChange = (point, typeOfRouter, field) => e => {
        e.preventDefault();
        if (!editModal.selectedPlId) {
            dispatch({
                type: 'handleSelectChange',
                payload: { point, typeOfRouter, field, value: e.target.value }
            });
        } else {
            dispatch({
                type: 'handleAddNewSelectChange',
                payload: { point, typeOfRouter, field, value: e.target.value }
            });
        }
    };

    const onSaveClick = (point, typeOfRouter) => e => {
        e.preventDefault();
        handleSaveClick(point, typeOfRouter);
    };

    const handleSaveClick = async (point, typeOfRouter) => {
        let url = '';
        if (!editModal.selectedPlId) {
            url =
                typeOfRouter === 'iPoints'
                    ? `https://admin.client.getshopster.net/outdoor/outdoor_ipoint/update_partial/${point.id}/`
                    : `https://admin.client.getshopster.net/adapp/outdoor_ad_space/update_partial/${point.id}/`;
        } else {
            url =
                typeOfRouter === 'iPoints'
                    ? `https://admin.client.getshopster.net/outdoor/outdoor_ipoint/create/`
                    : `https://admin.client.getshopster.net/adapp/outdoor_ad_space/create/`;
        }

        let data = {};
        if (!editModal.selectedPlId) {
            data =
                typeOfRouter === 'iPoints'
                    ? {
                          is_directed: point.is_directed,
                          marker: point.marker,
                          latitude: point.latitude,
                          longitude: point.longitude,
                          orientation_azimuth: point.is_directed ? Number(point.orientation_azimuth) : null
                      }
                    : {
                          name: point.name,
                          ext_id: point.ext_id,
                          ad_space_type: Number(point.ad_space_type.id),
                          latitude: point.latitude,
                          longitude: point.longitude,
                          orientation_azimuth: Number(point.orientation_azimuth)
                      };
        } else {
            data =
                typeOfRouter === 'iPoints'
                    ? {
                          is_directed: point.is_directed,
                          marker: point.marker,
                          latitude: point.latitude,
                          longitude: point.longitude,
                          orientation_azimuth: point.is_directed ? Number(point.orientation_azimuth) : null,
                          project_location: editModal.selectedPlId
                      }
                    : {
                          name: point.name,
                          ext_id: point.ext_id,
                          ad_space_type: Number(point.ad_space_type.id),
                          latitude: point.latitude,
                          longitude: point.longitude,
                          orientation_azimuth: Number(point.orientation_azimuth),
                          project_location: editModal.selectedPlId
                      };
        }

        const headers = {
            'x-token': token
        };

        try {
            const response = await axios({
                method: !editModal.selectedPlId ? 'patch' : 'post',
                url,
                headers,
                data
            });
            dispatch({
                type: 'handlePatchSucсess'
            });
            typeOfRouter === 'iPoints'
                ? getData(`https://admin.client.getshopster.net/outdoor/outdoor_ipoints/`, 'getIPoints')
                : getData(`https://admin.client.getshopster.net/adapp/outdoor_ad_spaces/`, 'getOutdoorSpaces');
        } catch (error) {
            dispatch({
                type: 'handlePatchError'
            });
            console.log(error);
        }
    };

    const getData = async (url, name) => {
        const headers = {
            'x-token': token
        };
        const response = await axios.get(url, { headers });
        const json = await response.data;
        dispatch({
            type: name,
            payload: {
                json
            }
        });
    };

    const handleCloseEditModal = useCallback(e => {
        e.preventDefault();
        dispatch({
            type: 'handleCloseEditModal'
        });
    }, []);

    const handlePickCursorCoords = useCallback(e => {
        e.preventDefault();
        dispatch({
            type: 'handlePickCursorCoords'
        });
    }, []);

    const cancelTitle = saveStatus === 'sucсess' ? 'Close' : 'Cancel';
    const saveTitle = saveStatus !== 'error' ? 'Save' : 'Error';

    try {
        const { typeOfRouter, id, show, selectedPlId, selectedPlName } = editModal;
        const pointsCatalog = innerData[typeOfRouter];
        const point = !selectedPlId ? pointsCatalog.filter(p => p.id === id)[0] : addNew;
        let showWarning = false;
        Object.keys(point).forEach(key => {
            if (point[key] === '') {
                showWarning = true;
            }
        });
        let title = '';
        if (!selectedPlId) {
            title = typeOfRouter === 'adSpaces' ? `AdSpace | ${point.name}` : `iPoint | ${point.marker} | ${point.id}`;
        } else {
            title = typeOfRouter === 'adSpaces' ? ` Add AdSpace into ${selectedPlName}` : `Add iPoint into ${selectedPlName}`;
        }

        return (
            <Form show={show} id={'editSpaceForm'}>
                <Title>{title}</Title>

                <List>
                    {Object.keys(allowEditCatalog[typeOfRouter]).map(item => {
                        const disabled = item === 'orientation_azimuth' && typeOfRouter === 'iPoints' && !point.is_directed;
                        return (
                            <Item key={item}>
                                {allowEditCatalog[typeOfRouter][item].field === 'input' ? (
                                    <Label>
                                        {item}
                                        <Input
                                            type={allowEditCatalog[typeOfRouter][item].type}
                                            step={allowEditCatalog[typeOfRouter][item].step}
                                            value={point[item]}
                                            checked={point[item]}
                                            onChange={inputsMap[item]}
                                            disabled={disabled}
                                        />
                                    </Label>
                                ) : (
                                    <Label>
                                        {item}
                                        <Select
                                            name="carlist"
                                            form="editSpaceForm"
                                            value={point[item].id}
                                            onChange={onSelectChange(point, typeOfRouter, item)}
                                        >
                                            {spacesTypesCatalog.map((spaceType, i) => {
                                                return (
                                                    <Option
                                                        key={`${spaceType.id}-${i}`}
                                                        value={spaceType.id}
                                                        // selected={spaceType.id === point[item].id}
                                                    >
                                                        {spaceType.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Label>
                                )}
                            </Item>
                        );
                    })}
                </List>

                <Button width="100%" isPickCursorCoords={isPickCursorCoords} onClick={handlePickCursorCoords}>
                    Pick Cursor Coordinates
                </Button>

                {isPickCursorCoords && (
                    <CursorCoords>
                        {`lat: ${cursorCoords.lat}`} <br /> {`lng: ${cursorCoords.lng}`}
                    </CursorCoords>
                )}
                <Warning showWarning={showWarning}>Fill all the fields!</Warning>
                <Buttons>
                    <Button width="48%" onClick={handleCloseEditModal}>
                        {cancelTitle}
                    </Button>
                    <Button width="48%" saveStatus={saveStatus} onClick={onSaveClick(point, typeOfRouter)}>
                        {saveTitle}
                    </Button>
                </Buttons>
            </Form>
        );
    } catch (error) {
        return null;
    }
});

export default EditModal;

const appearence = keyframes`
  0% { right: -400px; }
  100% { right: 0px; }
`;

const opacity = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

const CursorCoords = styled.div`
    margin-top: 10px;
    font-size: 18px;
`;

const Warning = styled.span`
    display: ${p => (p.showWarning ? 'flex' : 'none')};
    width: 100%;
    justify-content: center;
    padding: 20px;
    animation: ${opacity} 1s ease-in-out infinite;
    color: red;
`;

const Form = React.memo(styled.form`
    box-sizing: border-box;
    width: 300px;
    height: 100%;
    padding: 10px;
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
    position: absolute;
    right: ${props => (!props.show ? '-300px' : '0px')};
    bottom: 0;
    z-index: 1002;
    background-color: #ddd;
    /* display: ${props => (props.show ? 'flex' : 'none')}; */
    flex-direction: column;
    animation: ${appearence} 0.3s ease-in-out;
`);

const Title = React.memo(styled.h4`
    padding: 0;
    margin: 0;
    list-style: none;
    /* font-family: HelveticaLight; */
    font-size: 18px;
    color: #1e90ff;
`);

const Buttons = React.memo(styled.div`
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0;
    margin-top: 20px;
    list-style: none;
    justify-content: space-between;
`);

const List = React.memo(styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
`);

const Item = React.memo(styled.li`
    padding: 5px 0;
    margin: 0;
    list-style: none;
`);

const Label = React.memo(styled.label`
    padding: 10px 0;
    margin: 0;
    display: flex;
    flex-direction: column;
`);

const Input = React.memo(styled.input`
    padding: 5px;
    margin: 0;
    margin-top: 5px;
    display: flex;
    font-size: 16px;
    outline: none;
    border: 1px solid #1e90ff;
    border-radius: 5px;
    pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
    background-color: ${p => (p.disabled ? '#ccc' : '#fff')};
    color: ${p => (p.disabled ? '#999' : '#000')};
`);

const Select = styled.select`
    margin: 0;
    margin-top: 5px;
    display: flex;
    font-size: 16px;
`;

const Option = styled.option`
    padding: 5px;
    margin: 0;
    margin-top: 5px;
    display: flex;
`;
