import React, { useContext } from 'react';
import Context from '../context';

import '../styles/index.css';

export default function Anchor({ plan }) {
    const { dispatch } = useContext(Context);

    return (
        <label className={'ModesContainer'}>
            <input
                checked={plan.isAnchor}
                onChange={() => {
                    if (plan.isAnchor) return;
                    dispatch({
                        // type: 'CHANGE_ANCHOR_PLAN',
                        type: 'SHOW_WARNING_MODAL',
                        payload: plan.id,
                    });
                }}
                type="radio"
                name={`anchor`}
                value={plan.floor}
            />
            <span className={'ModesCheckmark Anchor'} />
        </label>
    );
}
