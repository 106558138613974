import React, { useContext, useMemo } from 'react';
import context from '../../context';
import styled from 'styled-components/macro';
import L from 'leaflet';
import ColorDot from '../color_dot';

const Container = styled.ul`
  list-style: none;
  overflow: auto;
  margin: 0;
  padding: 0;
`;

const Li = styled.li`
  border-bottom: 1px solid #777;
  margin-bottom: 5px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  /* span {
    margin-left: 5px;
  } */
`;

const LayersPanel = React.memo(({ ...props }) => {
  const { state, dispatch } = useContext(context);
  const { projectLocationsByPlType, currentBounds } = state;

  const allVisiblePLs = () => {
    const arr = [];
    Object.keys(projectLocationsByPlType).forEach(key => {
      const plByType = projectLocationsByPlType[key];
      if (plByType.visible && plByType.data !== undefined) {
        Object.keys(plByType.data).forEach(plId => {
          arr.push({ ...plByType.data[plId], color: plByType.color });
        });
      }
    });
    return arr.filter(item => {
      const lat = Number(item.latitude);
      const lng = Number(item.longitude);
      return currentBounds.contains(L.latLng(lat, lng));
    });
  };

  const list = useMemo(() => {
    return allVisiblePLs().map(item => {
      return (
        <Li key={`${item.id}`}>
          <ColorDot color={item.color} diameter={10} marginRight={'5px'} />
          <span>{`${item.id} | ${item.name}`}</span>
        </Li>
      );
    });
  }, [allVisiblePLs(), currentBounds]);

  // console.log(allVisiblePLs());

  return useMemo(() => {
    if (currentBounds === undefined) {
      return null;
    } else {
      return <Container>{list}</Container>;
    }
  }, [currentBounds, allVisiblePLs()]);
});

export default LayersPanel;
