import React, { useState, useEffect, useReducer, useContext } from 'react';
import styled from 'styled-components/macro';
import axios from 'axios';
import Map from './components/map';
import ControlPanel from './components/control_panel';
import Context from './context';
import TransitionsModal from '../modal';
import ReloadPage from './components/reload_page';
import { GlobalContext } from '../../App';
import Reducer from './reducer';
import { parseQuery } from '../tools';
import { VALIDATION_REQUEST_INTERVAL } from '../../constants/constants';

const Container = styled.div`
    display: flex;
    height: 100%;
`;

// custom hook
const useAPI = (locationsUrl, plansUrl, id, token) => {
    const [data, setData] = useState({});
    const [query, setQuery] = useState({});

    useEffect(() => {
        const query = parseQuery(window.location.search);
        setQuery((prev) => query);
    }, []);

    useEffect(() => {
        getData(query);
    }, [query]);

    const getData = async (query) => {
        try {
            const currentUUID = query.location_id ? query.location_id : id;
            const currentPlanId = query.plan_id ? query.plan_id : null;
            if (!currentUUID) return;
            const headers = {
                'X-Token': token,
                'Content-type': 'application/json',
            };
            let locationData, plansData;
            await axios
                .get(`${locationsUrl}${currentUUID}.json`, { headers })
                .then((response) => response.data)
                .then((data) => {
                    locationData = data;
                    axios
                        .get(`${plansUrl}?location_id=${currentUUID}`, { headers })
                        .then((response) => response.data)
                        .then((data) => {
                            plansData = data;
                            setData({ locationData, plansData, currentPlanId });
                        });
                });
        } catch (error) {
            console.log('NETWORK ERROR', error);
        }
    };

    return { data, query, id };
};

const GeoMap = () => {
    const initialState = useContext(Context);
    const { token, urls } = useContext(GlobalContext);
    const [state, dispatch] = useReducer(Reducer, initialState);
    const savedData = useAPI(`${urls.LOCATIONS_URL.url}`, `${urls.PLANS_URL.url}`, state.objectUUID, token);

    const doValidationRequest = async ({ token, locationId }) => {
        const headers = {
            'X-Token': token,
            'Content-type': 'application/json',
        };
        await axios
            .get(`${urls.PLANS_URL.url}?location_id=${locationId}`, { headers })
            .then((response) => response.data)
            .then((data) => {
                dispatch({
                    type: 'CHECK_OUTSIDE_CHANGING_PLANS',
                    payload: data,
                });
            });
    };

    useEffect(() => {
        if (!token || !savedData.query) return;
        const interval = setInterval(() => {
            doValidationRequest({ token, locationId: savedData.query.location_id });
        }, VALIDATION_REQUEST_INTERVAL);
        return () => clearInterval(interval);
    }, [token, savedData.query]);

    useEffect(() => {
        if (Object.keys(savedData.data).length !== 0) {
            dispatch({
                type: 'GET_DATA',
                payload: savedData.data,
            });
        }
    }, [savedData.data]);

    useEffect(() => {
        if (Object.keys(state.data).length === 0) return;
        // timeout из-за cors
        setTimeout(
            () =>
                dispatch({
                    type: 'SHOW_PLANS',
                    payload: true,
                }),
            2000
        );
    }, [state.data]);

    return (
        <Context.Provider value={{ state, dispatch }}>
            <Container>
                <TransitionsModal open={state.isPlansChangedOutside}>
                    <ReloadPage />
                </TransitionsModal>
                <ControlPanel />
                <Map />
            </Container>
        </Context.Provider>
    );
};

export default GeoMap;
