import React from 'react';

const Context = React.createContext({
    data: {},
    isPlansChangedOutside: false,
    initialPlans: null,
    is_geomapped: false,
    // objectUUID: '0a507db0-ab20-48cb-913a-d2cb71ee0527',
    // objectUUID: 'ade46ed0-ad83-4a4d-8e82-053b7544a08e',
    objectUUID: '',
    isSaveAllNeeded: false,
    mapCenter: { lat: 55.751768, lng: 37.61714 },
    status: 'no plans showed',
    plans: [],
    map: null,
    currentPlanId: null,
    azimuth: 0,
    showPlans: false,
    zoom: 18,
    showWarningModal: false,
    changedPlanIds: [],
    initialData: {
        plans: [],
        azimuth: 0,
    },
});

export default Context;
