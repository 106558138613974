import React, { useContext, useMemo, useEffect } from 'react';
import context from '../context';
import styled from 'styled-components/macro';

import Search from './search';

const ProjectLocationsList = React.memo(() => {
    const { state, dispatch } = useContext(context);
    const { projectLocationsData, selectedPL } = state;

    const onTargetClick = item => e => {
        dispatch({
            type: 'aimOnPoint',
            center: { lat: item.latitude, lng: item.longitude }
        });
    };

    const onPLTitleClick = item => e => {
        dispatch({
            type: 'handleCloseEditModal'
        });
        dispatch({
            type: 'selectPL',
            id: item.id
        });
        dispatch({
            type: 'aimOnPoint',
            center: { lat: item.latitude, lng: item.longitude }
        });
        dispatch({
            type: 'setQuery',
            pl_id: item.id
        });
    };

    const filteredplList = useMemo(() => {
        if (projectLocationsData) {
            const plList = Object.keys(projectLocationsData)
                .filter(item => projectLocationsData[item].pl_type === 'outdoor_type')
                .sort((a, b) => Number(b) - Number(a));
            return state.searchValue.length === 0
                ? plList
                : plList.filter(plId => {
                      const name = `${projectLocationsData[plId].id} ${projectLocationsData[plId].name}`;
                      return name.toLowerCase().includes(state.searchValue.toLowerCase());
                  });
        } else {
            return null;
        }
    }, [projectLocationsData, selectedPL, state.searchValue]);

    const refs = useMemo(() => {
        return filteredplList
            ? filteredplList.reduce((acc, value) => {
                  acc[value] = React.createRef();
                  return acc;
              }, {})
            : null;
    }, [filteredplList]);

    // console.log(refs);

    useEffect(() => {
        if (refs && refs[selectedPL] && refs[selectedPL].current) {
            refs[selectedPL].current.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    }, [refs, selectedPL]);

    const list = useMemo(() => {
        if (filteredplList && refs) {
            return filteredplList.map(item => {
                const selected = selectedPL.length === 0 ? true : selectedPL.length !== 0 && item !== String(selectedPL[0]) ? false : true;
                return (
                    <Item key={`${item}`} ref={refs[item]}>
                        <SVG onClick={onTargetClick(projectLocationsData[item])}>
                            <svg height="20" viewBox="0 0 50 50" width="20" xmlns="http://www.w3.org/2000/svg">
                                <path d="m23 0v4.0957031c-9.981298.9489961-17.953104 8.9227909-18.9023438 18.9042969h-4.0976562v4h4.0976562c.9492398 9.981506 8.9210458 17.955301 18.9023438 18.904297v4.095703h4v-4.097656c9.981223-.949213 17.953131-8.921121 18.902344-18.902344h4.097656v-4h-4.097656c-.949213-9.981223-8.921121-17.953131-18.902344-18.9023438v-4.0976562zm4 8.1269531c7.805997.9099644 13.963083 7.0670499 14.873047 14.8730469h-2.873047v4h2.873047c-.909964 7.805997-7.06705 13.963083-14.873047 14.873047v-2.873047h-4v2.871094c-7.803628-.911192-13.9631575-7.06574-14.8730469-14.871094h2.8730469v-4h-2.8730469c.9098894-7.805354 7.0694189-13.9599017 14.8730469-14.8710938v2.8710938h4zm-2 9.8730469a7 7 0 0 0 -7 7 7 7 0 0 0 7 7 7 7 0 0 0 7-7 7 7 0 0 0 -7-7z" />
                            </svg>
                        </SVG>
                        <ItemTitle
                            selected={selected}
                            onClick={onPLTitleClick(projectLocationsData[item])}
                        >{`${projectLocationsData[item].id} ${projectLocationsData[item].name}`}</ItemTitle>
                    </Item>
                );
            });
        } else {
            return null;
        }
    }, [filteredplList, refs]);

    return (
        <Container>
            <TitleContainer>
                <Search />
                <Title>Project Locations</Title>
            </TitleContainer>
            <List>{list}</List>
        </Container>
    );
});

export default ProjectLocationsList;

const Container = styled.div`
    box-sizing: border-box;
    flex-shrink: 0;
    overflow: auto;
`;

const TitleContainer = styled.div`
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: normal;
    padding: 10px 0 10px 0;
    margin-bottom: 10px;
    padding-bottom: 5px;
    position: sticky;
    top: 0;
    background-color: #eee;
    z-index: 20;
`;

const Title = styled.h4`
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 10px;
    border-bottom: 1px solid #777;
    padding-bottom: 5px;
    top: 0;
    color: #1e90ff;
`;

const List = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

const Item = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
`;

const ItemTitle = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 0px;
    color: ${props => (props.selected ? '#000' : '#999')};
`;

const SVG = styled.div`
    cursor: pointer;
    display: flex;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    opacity: 0.6;
    :hover {
        opacity: 0.9;
    }
    transform: opacity 0.5s;
`;
