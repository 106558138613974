import React, { useEffect, useReducer, useContext } from 'react';
import styled from 'styled-components/macro';
import axios from 'axios';
import { parseQuery } from '../tools';
import OutdoorMap from './components/map';
import ControlPanel from './components/control_panel/control_panel';
import EditModal from './components/modals/edit_modal';
import OverlayModal from './components/modals/overlay_modal';

import { getData } from './API';

import OutdoorMapServiceContext from './context';
import { GlobalContext } from '../../App';
import SpacesReducer from './reducer';

const ShowPolygonsCatalogModal = styled.div`
  cursor: pointer;
  user-select: none;
  position: absolute;
  width: 100px;
  height: 30px;
  bottom: 30px;
  right: 30px;
  background-color: #ccc;
  overflow: hidden;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  z-index: 1001;
`;

const MapContainer = styled.section`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: 100%;

  background-color: #fff;
  box-sizing: border-box;
  transition: height 0.5s ease-in-out;
`;

const InnerContainer = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-sizing: border-box;
  transition: height 0.5s ease-in-out;
  display: flex;
  .leaflet-container {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    cursor: ${props => (props.isPickCursorCoords ? 'crosshair' : '')};
  }
`;

const OutdoorSpaces = React.memo(() => {
  const initialState = useContext(OutdoorMapServiceContext);
  const { token } = useContext(GlobalContext);
  const [state, dispatch] = useReducer(SpacesReducer, initialState);
  const { isPickCursorCoords } = state;

  useEffect(() => {
    if (!token) return;
    getData(
      token,
      `https://admin.getshopster.net/ownership_hierarchy/project_locations/`,
      'getProjectLocations',
      dispatch
    );
    getData(
      token,
      `https://outdoor-maps.getshopster.net/regions/region_groups/`,
      'getRegionGroups',
      dispatch
    );
    // getData(
    //   token,
    //   `https://outdoor-maps.getshopster.net/regions/all_populations/`,
    //   'getAllPopulations',
    //   dispatch
    // );
    getData(
      token,
      `https://outdoor-maps.getshopster.net/malls/malls/`,
      'getMalls',
      dispatch
    );
  }, [token]);

  useEffect(() => {
    if (!token) return;
    const query = parseQuery(window.location.search);
    const { projectLocationsData } = state;
    if (query.pl_id) {
      dispatch({
        type: 'selectPL',
        id: Number(query.pl_id)
      });
      const point = projectLocationsData[Number(query.pl_id)];

      dispatch({
        type: 'aimOnPoint',
        center: { lat: point.latitude, lng: point.longitude }
      });
    }
  }, [state.projectLocationsData]);

  const onClick = e => {
    dispatch({
      type: 'togglePolygonsCatalogModal'
    });
  };

  

  return (
    <OutdoorMapServiceContext.Provider value={{ state, dispatch }}>
      <MapContainer>
        <ControlPanel />
        <InnerContainer>
          <OutdoorMap />
        </InnerContainer>
        <EditModal />
        {state.overlayModal.show ? <OverlayModal /> : null}
      </MapContainer>
    </OutdoorMapServiceContext.Provider>
  );
});

export default OutdoorSpaces;
