import React, { useRef, useEffect, useContext, useMemo, useState } from 'react';
// import { EditControl } from 'react-leaflet-draw';
import {
  Map,
  TileLayer,
  Marker,
  Popup,
  LayerGroup,
  LayersControl,
  AttributionControl,
  FeatureGroup,
  Pane
} from 'react-leaflet';
import MarkerClusterGroup from './react-leaflet-markercluster';
import L from 'leaflet';
import { Portal } from 'react-leaflet-portal';
import context from '../context';
import { GlobalContext } from '../../../App';

import '../leaflet/leaflet.css';
import '../style/markercluster-styles.css';
import Circles from './circles';
import Sectors from './sectors';
import styled from 'styled-components/macro';
import ZoomWorldControl from './zoom-world-control';
import EditModal from './edit_modal';

const adSpaceIcon = new L.Icon({
  iconUrl: require('../img/pin.svg'),
  iconRetinaUrl: require('../img/pin.svg'),
  iconAnchor: [10, 30],
  popupAnchor: [0, 0],
  shadowUrl: require('../img/pin.png'),
  shadowSize: new L.Point(50, 20),
  shadowAnchor: new L.Point(10, 15),
  iconSize: new L.Point(20, 30)
});

const iPointIcon = new L.Icon({
  iconUrl: require('../img/pin_blue.svg'),
  iconRetinaUrl: require('../img/pin_blue.svg'),
  iconAnchor: [5, 15],
  popupAnchor: [0, 0],
  // shadowUrl: require('.../img/pin.png'),
  // shadowSize: new L.Point(50, 20),
  // shadowAnchor: new L.Point(10, 15),
  iconSize: new L.Point(10, 15)
});

const CursorCoords = styled.div`
  padding: 5px;
  background-color: whitesmoke;
  border: 1px solid #777;
  border-radius: 5px;
  font-size: 14px;
`;

const OutdoorMap = React.memo(() => {
  const { state, dispatch } = useContext(context);
  const { maps } = useContext(GlobalContext);
  const [circlesCenters, setCirclesCenters] = useState([]);
  const {
    isPickCursorCoords,
    innerData,
    mapCenter,
    initialZoom,
    renderMode,
    selectedSpaces,
    showIPoints,
    allCenters,
    // cursorCoords,
    editModal,
    activeLayerId,
    addNew
  } = state;

  useEffect(() => {
    const circlesCenters = Array.from(allCenters).map(item => {
      const arr = item.split('_');
      return {
        latitude: arr[0],
        longitude: arr[1]
      };
    });
    setCirclesCenters(prev => circlesCenters);
  }, [allCenters]);

  const mapRef = useRef(null);

  useEffect(() => {
    return () => {
      const center = mapRef.current.leafletElement.getCenter();
      const currentZoom = mapRef.current.leafletElement._zoom;
      dispatch({
        type: 'handleSaveCenter',
        payload: { center }
      });
      dispatch({
        type: 'setInitialZoom',
        payload: { currentZoom }
      });
    };
  }, [mapRef.current]);

  // const onSectorClick = (sectorId, typeOfRouter) => e => {
  //   switch (typeOfRouter) {
  //     case 'adSpaces':
  //       dispatch({
  //         type: 'onSectorClick',
  //         payload: { sectorId }
  //       });
  //       break;
  //     case 'iPoints':
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const onZoom = () => {
    const currentZoom = mapRef.current.leafletElement._zoom;
    dispatch({
      type: 'handleZoomChange',
      payload: { currentZoom }
    });
    if (currentZoom > 17) {
      dispatch({
        type: 'writePolygonsCatalog',
        payload: { mapRef, innerData }
      });
    }
  };

  const renderMarkers = (spaces, icon) => {
    if (spaces) {
      const markersCoord = new Set(
        spaces.map((item, i) => {
          return { lat: item.latitude, lng: item.longitude };
        })
      );

      return Array.from(markersCoord).map((item, i) => {
        return (
          <Marker position={{ lat: item.lat, lng: item.lng }} key={`marker-${i}`} icon={icon}>
            <Popup>{`${item.lat} ${item.lng}`}</Popup>
          </Marker>
        );
      });
    }
  };

  const createClusterCustomIcon = cluster => {
    const childCount = cluster.getChildCount();
    let c = ' custom-marker-cluster-';
    if (childCount < 10) {
      c += 'small';
    } else if (childCount < 100) {
      c += 'medium';
    } else {
      c += 'large';
    }

    return new L.divIcon({
      html: '<div><span>' + childCount + '</span></div>',
      className: 'custom-marker-cluster' + c,
      iconSize: new L.Point(40, 40)
    });
  };

  const createSquareClusterCustomIcon = cluster => {
    const childCount = cluster.getChildCount();
    let c = ' custom-marker-cluster-';
    if (childCount < 10) {
      c += 'small';
    } else if (childCount < 100) {
      c += 'medium';
    } else {
      c += 'large';
    }

    return new L.divIcon({
      html: '<div><span>' + childCount + '</span></div>',
      className: 'custom-marker-cluster-square' + c,
      iconSize: new L.Point(40, 40)
    });
  };

  const onZoomWorldClick = () => {
    const map = mapRef.current.leafletElement;
    map.setZoom(2);
  };

  const onMapMouseMove = e => {
    if (!isPickCursorCoords) return;
    const cursorCoords = {
      lat: e.latlng.lat.toFixed(5),
      lng: e.latlng.lng.toFixed(5)
    };
    dispatch({
      type: 'setCursorCoords',
      payload: { cursorCoords }
    });
  };

  const onMapClick = e => {
    if (!isPickCursorCoords) return;
    const cursorCoords = {
      lat: e.latlng.lat.toFixed(5),
      lng: e.latlng.lng.toFixed(5)
    };
    dispatch({
      type: 'handleMapClick',
      payload: { cursorCoords }
    });
  };

  const center = [mapCenter.lat, mapCenter.lng];
  const map = mapRef;

  const renderAdSpacesClusters =
    renderMode === 'markers' ? (
      <MarkerClusterGroup
        spiderfyOnMaxZoom={false}
        disableClusteringAtZoom={12}
        iconCreateFunction={createClusterCustomIcon}
        polygonOptions={{
          weight: 1
        }}
      >
        {renderMarkers(innerData.adSpaces, adSpaceIcon)}
      </MarkerClusterGroup>
    ) : (
      <LayerGroup key={`AdSpaces-sectors`}>
        <Sectors data={innerData.adSpaces} typeOfRouter={'adSpaces'} map={map} />
      </LayerGroup>
    );

  const renderAdSpacesInnerClusters =
    renderMode === 'markers' ? null : (
      <LayerGroup key={`AdSpaces-circles`}>
        <Sectors data={innerData.adSpaces} typeOfRouter={'adSpaces'} map={map} isInner={true} />
      </LayerGroup>
    );

  const renderIPointsClusters =
    renderMode === 'markers' ? (
      renderMarkers(innerData.iPoints, iPointIcon)
    ) : (
      <LayerGroup key={`IPoints-clusters`}>
        <Sectors data={innerData.iPoints} typeOfRouter={'iPoints'} map={map} />
      </LayerGroup>
    );
  const renderIPointsInnerClusters =
    renderMode === 'markers' ? null : (
      <LayerGroup key={`IPoints-circles`}>
        {/* <Circles data={innerData.iPoints} typeOfRouter={'iPoints'}  /> */}
        <Sectors data={innerData.iPoints} typeOfRouter={'iPoints'} map={map} isInner={true} />
      </LayerGroup>
    );

  const renderCircles =
    renderMode === 'markers' ? null : (
      <LayerGroup key={`circles`}>
        <Circles data={circlesCenters} />
      </LayerGroup>
    );

  const renderAddNew =
    renderMode === 'markers' || Object.keys(addNew).length === 0 ? null : (
      <LayerGroup key={`addNew`}>
        <Sectors data={addNew} typeOfRouter={editModal.typeOfRouter} map={map} mode={'addNew'} />
      </LayerGroup>
    );

  const renderMaps = useMemo(
    () =>
      maps.map((item, i) => {
        const checked = item.baseLayer.checked ? item.baseLayer.checked : false;
        return (
          <LayersControl.BaseLayer
            name={item.baseLayer.name}
            checked={checked}
            key={`LayersControl.BaseLayer-${i}`}
          >
            <TileLayer attributionPrefix={false} minZoom={0} maxZoom={20} {...item.tileLayer} />
          </LayersControl.BaseLayer>
        );
      }),
    [maps]
  );


  

  try {
    return (
      center &&
      maps.length !== 0 && (
        <Map
          center={mapCenter}
          zoom={initialZoom}
          maxZoom={18}
          ref={mapRef}
          onZoom={onZoom}
          //   attributionControl={true}
          onMouseMove={onMapMouseMove}
          onClick={onMapClick}
        >
          <Pane name={'renderAddNew'} style={{ zIndex: 606 }}>
            {renderAddNew}
          </Pane>
          <Pane name={'renderCircles'} style={{ zIndex: 605 }}>
            {renderCircles}
          </Pane>
          <Pane name={'renderIPointsInnerClusters'} style={{ zIndex: 604 }}>
            {showIPoints && renderIPointsInnerClusters}
          </Pane>
          <Pane name={'renderAdSpacesInnerClusters'} style={{ zIndex: 603 }}>
            {renderAdSpacesInnerClusters}
          </Pane>
          <Pane name={'renderIPointsClusters'} style={{ zIndex: 602 }}>
            {showIPoints && renderIPointsClusters}
          </Pane>
          <Pane name={'renderAdSpacesClusters'} style={{ zIndex: 601 }}>
            {renderAdSpacesClusters}
          </Pane>

          {/* <AttributionControl position="bottomright" prefix="" /> */}

          <Portal position="topleft">
            <ZoomWorldControl onZoomWorldClick={onZoomWorldClick}>2</ZoomWorldControl>
          </Portal>
          <LayersControl position="topright">{renderMaps}</LayersControl>

          <Portal position="bottomleft">
            {/* <CursorCoords>
                {`Cursor coordinates: lat: ${cursorCoords.lat}  lng: ${cursorCoords.lng}`}
              </CursorCoords> */}
          </Portal>
        </Map>
      )
    );
  } catch (error) {
    console.log(error);

    return null;
  }
});

export default OutdoorMap;
