import React, { useContext, useRef } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import cn from 'classnames';
import Save from '@material-ui/icons/Save';
import Context from '../context';
import { GlobalContext } from '../../../App';
import '../styles/index.css';
import { savePlanToServer } from '../../tools';

const Modes = ({ plan, changedPlanIds, isAzimuthChanged }) => {
    const { dispatch } = useContext(Context);
    const { token, toggleAlert, urls } = useContext(GlobalContext);

    const onModeChange = (id) => (e) => {
        dispatch({
            type: 'MODE_CHANGE',
            payload: {
                id,
                value: e.target.value,
            },
        });

        e.target.blur();
    };

    const onSavePlanClick = (plan) => (e) => {
        savePlanToServer({ plan, dispatch, token, toggleAlert, urls });
    };

    const modes = plan.isAnchor ? ['lock', 'translate', 'rotate'] : ['lock', 'translate'];

    return (
        <Form>
            {modes.map((item, i) => {
                const CheckmarkClass = cn({
                    ModesCheckmark: true,
                    ModesRotate: item === 'rotate',
                    ModesTranslate: item === 'translate',
                    ModesLock: item === 'lock',
                });
                return (
                    <div className={'ModesContainer'} key={`mode-${i}`}>
                        <label>
                            <input
                                checked={plan.editMode === item}
                                onChange={onModeChange(plan.id)}
                                type="radio"
                                name={`mode${plan.floor}`}
                                value={item}
                            />
                            <span className={CheckmarkClass} />
                        </label>
                    </div>
                );
            })}
            {/* {changedPlanIds.includes(plan.id) && !isAzimuthChanged ? <StSave color={'error'} onClick={onSavePlanClick(plan)} /> : null} */}
        </Form>
    );
};

export default Modes;

const Form = styled.form`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 40px;
    width: 100px;
    margin-left: 10px;
`;

function blinkingEffect() {
    return keyframes`
      50% {
        opacity: 0;
      }
    `;
}

const StSave = styled(Save)`
    cursor: pointer;
    animation: ${blinkingEffect} 0.5s ease-in-out infinite;
    :hover {
        animation: ${blinkingEffect} 2s ease-in-out infinite;
    }
`;
