import axios from 'axios';
import Crypto from 'crypto-js';
import L from 'leaflet';
import { headersNames } from '../constants/headers';
import { lowerFirst } from 'lodash';

const convertFromLatLngToCoordPoint = (latLng) => {
    return { type: 'Point', coordinates: [latLng.lat, latLng.lng] };
};

export const convertFromCoordPointToLatLng = (arr) => {
    try {
        return L.latLng(arr[0], arr[1]);
    } catch (error) {
        return undefined;
    }
};

export const saveAll = async ({ state, dispatch, token, urls, origin }) => {
    const { locationId } = state.data;
    const { mapCenter, azimuth, plans, is_geomapped } = state;
    const url = `${urls.LOCATIONS_GEOMAPPING_URL.url}${locationId}/`;
    const headers = {
        [headersNames.TOKEN]: token,
        'Content-type': 'application/json',
    };
    const responseType = 'json';
    const data = {
        id: locationId,
        point: {
            type: 'Point',
            coordinates: [Number(mapCenter.lat), Number(mapCenter.lng)],
        },
        azimuth,
        is_geomapped,
        plans: plans.map((plan) => {
            let is_anchor = plan.isAnchor;
            if (state.showWarningModal && state.showWarningModal.planToBeAnchor) {
                is_anchor = plan.id === state.showWarningModal.planToBeAnchor;
            }
            return {
                id: plan.id,
                is_anchor,
                geo_top_left: convertFromLatLngToCoordPoint(plan.corners[0]),
                geo_top_right: convertFromLatLngToCoordPoint(plan.corners[1]),
                geo_bottom_left: convertFromLatLngToCoordPoint(plan.corners[2]),
                geo_bottom_right: convertFromLatLngToCoordPoint(plan.corners[3]),
            };
        }),
    };
    try {
        const response = await axios({ method: 'PUT', url, responseType, data, headers });
        window.location.replace(`${window.location.href}&origin=${origin}`);
    } catch (error) {}
};

export const savePlanToServer = async ({ plan, dispatch, token, toggleAlert, urls }) => {
    const url = `${urls.PLANS_URL.url}${plan.id}/`;
    const headers = {
        [headersNames.TOKEN]: token,
        'Content-type': 'application/json',
    };
    const responseType = 'json';
    const data = {
        geo_top_left: convertFromLatLngToCoordPoint(plan.corners[0]),
        geo_top_right: convertFromLatLngToCoordPoint(plan.corners[1]),
        geo_bottom_left: convertFromLatLngToCoordPoint(plan.corners[2]),
        geo_bottom_right: convertFromLatLngToCoordPoint(plan.corners[3]),
        is_anchor: plan.is_anchor,
    };
    try {
        const response = await axios({ method: 'PATCH', url, responseType, data, headers });
        dispatch({
            type: 'SUCCESSFUL_SAVE_PLAN_TO_SERVER',
            payload: plan.id,
        });
        toggleAlert({ open: true, text: `Plan ${plan.id} saved` });
    } catch (error) {
        toggleAlert({ open: true, text: `Error! Plan ${plan.id} hasn't been saved`, severity: 'error' });
        console.warn('SAVE ERROR >>>> ', error);
    }
};

const colors = {
    getHeatColor: function (val) {
        var x = 2.0 / (1 + Math.exp(-val * 5)) - 1;
        var r = Math.min(Math.max(0, 1.5 - Math.abs(1.0 - 4.0 * (x - 0.5))), 1);
        var g = Math.min(Math.max(0, 1.5 - Math.abs(1.0 - 4.0 * (x - 0.25))), 1);
        var b = Math.min(Math.max(0, 1.5 - Math.abs(1.0 - 4.0 * x)), 1);
        return 'rgba(' + parseInt(r * 255) + ',' + parseInt(g * 255) + ',' + parseInt(b * 255) + ',0.5)';
    },
    HSV2RGB: function (h, s, v) {
        var r, g, b, i, f, p, q, t;
        // if (arguments.length === 1) {
        //     s = h.s, v = h.v, h = h.h;
        // }
        i = Math.floor(h * 6);
        f = h * 6 - i;
        p = v * (1 - s);
        q = v * (1 - f * s);
        t = v * (1 - (1 - f) * s);
        switch (i % 6) {
            case 0:
                r = v;
                g = t;
                b = p;
                break;
            case 1:
                r = q;
                g = v;
                b = p;
                break;
            case 2:
                r = p;
                g = v;
                b = t;
                break;
            case 3:
                r = p;
                g = q;
                b = v;
                break;
            case 4:
                r = t;
                g = p;
                b = v;
                break;
            case 5:
                r = v;
                g = p;
                b = q;
                break;
            default:
                break;
        }
        return {
            r: Math.round(r * 255),
            g: Math.round(g * 255),
            b: Math.round(b * 255),
        };
    },
    RGB2HEX: function (r, g, b) {
        r = r.toString(16);
        g = g.toString(16);
        b = b.toString(16);
        if (r.length === 1) r = '0' + r;
        if (g.length === 1) g = '0' + g;
        if (b.length === 1) b = '0' + b;
        return '#' + (r + g + b).toUpperCase();
    },
};

export const generateColorForObjects = (str) => {
    const hash = Crypto.MD5(str.toString()).toString();
    const hash_hue = parseInt(hash.slice(0, 6), 16) / 65536;
    const hue = hash_hue; //Math.random();
    const saturation = 1;
    const lightness = 1;
    const c = colors.HSV2RGB(hue, saturation, lightness);
    return colors.RGB2HEX(c.r, c.g, c.b);
};

export const _rotateBy = (prevCorners, angle, crs, zoom) => {
    let crs_center = crs.latLngToPoint(calculateMidPoint(prevCorners), zoom),
        i,
        p,
        newCorners = [],
        q;

    for (i = 0; i < 4; i++) {
        p = crs.latLngToPoint(prevCorners[i], zoom).subtract(crs_center);
        q = new L.Point(Math.cos(angle) * p.x - Math.sin(angle) * p.y, Math.sin(angle) * p.x + Math.cos(angle) * p.y);
        newCorners[i] = crs.pointToLatLng(q.add(crs_center), zoom);
    }
    return newCorners;
};

export const calculateMidPoint = (corners) => {
    const fi1 = toRadians(corners[0].lat);
    const lambda1 = toRadians(corners[0].lng);
    const fi2 = toRadians(corners[3].lat);
    const lambda2 = toRadians(corners[3].lng);

    const Bx = Math.cos(fi2) * Math.cos(lambda2 - lambda1);
    const By = Math.cos(fi2) * Math.sin(lambda2 - lambda1);

    const fi3 = toDegrees(Math.atan2(Math.sin(fi1) + Math.sin(fi2), Math.sqrt((Math.cos(fi1) + Bx) * (Math.cos(fi1) + Bx) + By * By)));
    const lambda3 = toDegrees(lambda1 + Math.atan2(By, Math.cos(fi1) + Bx));

    return L.latLng(fi3, lambda3);
};

export const toRadians = (x) => (x * Math.PI) / 180;

export const toDegrees = (radians) => radians * (180 / Math.PI);

export const calculateCorners = (map, plan) => {
    try {
        const { x, y } = map._size;
        const { scale, width, height } = plan;
        // console.log('scale -----> ',scale);

        const mapXmeters = map.containerPointToLatLng([0, y]).distanceTo(map.containerPointToLatLng([x, y]));
        const mapScale = x / mapXmeters;
        const scaledPictureWidth = (width / scale) * mapScale;
        const scaledPictureHeight = (height / scale) * mapScale;
        const leftUpper = map.containerPointToLatLng(L.point(x / 4, y / 4));
        const rightUpper = map.containerPointToLatLng(L.point(x / 4 + scaledPictureWidth, y / 4));
        const rightLower = map.containerPointToLatLng(L.point(x / 4 + scaledPictureWidth, y / 4 + scaledPictureHeight));
        const leftLower = map.containerPointToLatLng(L.point(x / 4, y / 4 + scaledPictureHeight));
        return [leftUpper, rightUpper, leftLower, rightLower];
    } catch (error) {}
};

export const calculateAzimuth = (corners) => {
    const y = Math.sin(toRadians(corners[1].lng) - toRadians(corners[3].lng)) * Math.cos(toRadians(corners[1].lat));
    const x =
        Math.cos(toRadians(corners[3].lat)) * Math.sin(toRadians(corners[1].lat)) -
        Math.sin(toRadians(corners[3].lat)) *
            Math.cos(toRadians(corners[1].lat)) *
            Math.cos(toRadians(corners[1].lng) - toRadians(corners[3].lng));
    const azimuth = toDegrees(Math.atan2(y, x));
    return azimuth;
};

export const parseQuery = (queryString) => {
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
};

export const removeParamsFromUrl = (hrefString, paramsArray) => {
    let result = '';
    const splittedHref = hrefString.split('?');
    const addressString = splittedHref[0];
    const queryString = splittedHref[1];
    if (!queryString || !paramsArray || paramsArray.length === 0) return hrefString;
    const filteredParams = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&').filter((item) => {
        let result = true;
        paramsArray.forEach((param) => {
            if (item.includes(param)) {
                result = false;
            }
        });
        return result;
    });
    result = `${addressString}?${filteredParams.join('&')}`;
    return result;
};
