export const maps = [
    { baseLayer: { name: 'G', checked: true }, tileLayer: { attribution: '', url: 'http://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}' } },
    {
        baseLayer: { name: 'OSM' },
        tileLayer: {
            attribution: '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
    },
    {
        baseLayer: { name: 'ArcGIS' },
        tileLayer: {
            attribution:
                'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        },
    },
    {
        baseLayer: { name: 'Black and White' },
        tileLayer: {
            attribution:
                'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            subdomains: 'abcd',
            ext: 'png',
            url: 'https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.{ext}',
        },
    },
];
