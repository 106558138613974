import L from 'leaflet';
import { clone, cloneDeep } from 'lodash';
import {
    generateColorForObjects,
    calculateAzimuth,
    calculateCorners,
    toRadians,
    toDegrees,
    convertFromCoordPointToLatLng,
    _rotateBy,
    calculateMidPoint,
} from '../tools';

export default function reducer(state, action) {
    const { map } = state;

    switch (action.type) {
        case 'SAVE_PLANS':
            return { ...state, status: action.payload };

        case 'UUID_CHANGE':
            return { ...state, objectUUID: action.payload };

        case 'CENTER_LAT_CHANGE':
            return { ...state, isSaveAllNeeded: true, is_geomapped: false, mapCenter: { ...state.mapCenter, lat: action.payload } };

        case 'CENTER_LNG_CHANGE':
            return { ...state, isSaveAllNeeded: true, is_geomapped: false, mapCenter: { ...state.mapCenter, lng: action.payload } };

        case 'SET_MAP':
            return {
                ...state,
                status: 'save plans',
                map: action.payload,
            };

        case 'ZOOM_CHANGE':
            return {
                ...state,
                status: 'save plans',
                zoom: action.payload,
            };

        case 'SHOW_WARNING_MODAL':
            return {
                ...state,
                showWarningModal: { show: true, planToBeAnchor: action.payload },
            };

        case 'CLOSE_WARNING_MODAL':
            return {
                ...state,
                showWarningModal: { show: false, planToBeAnchor: null },
            };

        case 'OPACITY_CHANGE':
            return {
                ...state,
                plans: state.plans.map((item) => {
                    return item.id === action.payload.id ? { ...item, opacity: action.payload.value / 100 } : { ...item };
                }),
            };

        case 'MODE_CHANGE':
            const newPlans = state.plans.map((item) => {
                return item.id === action.payload.id ? { ...item, editMode: action.payload.value } : { ...item, editMode: 'lock' };
            });
            return {
                ...state,
                plans: newPlans,
            };

        case 'CHANGE_ANCHOR_PLAN':
            const plans11 = state.plans.map((item) => {
                return item.id !== action.payload ? { ...item, isAnchor: false } : { ...item, isAnchor: true };
            });
            return { ...state, plans: plans11, isSaveAllNeeded: true, is_geomapped: false };

        case 'UPDATE_CORNERS':
            const prevAzimuth = state.azimuth;
            const newAzimuth = calculateAzimuth(action.payload.corners);
            const crs = map.current.leafletElement.options.crs;
            const updatedPlans = state.plans.map((item) => {
                const prevCorners = item.corners;
                const delta = toRadians(newAzimuth - prevAzimuth);
                const otherCorners = _rotateBy(prevCorners, delta, crs, map.current.leafletElement._zoom);
                return item.id === action.payload.id
                    ? { ...item, corners: action.payload.corners, center: calculateMidPoint(action.payload.corners) }
                    : { ...item, corners: otherCorners, center: calculateMidPoint(otherCorners) };
            });

            const changedPlanIds = Array.from(new Set([...state.changedPlanIds, action.payload.id]));

            return {
                ...state,
                isSaveAllNeeded: true,
                is_geomapped: false,
                changedPlanIds,
                azimuth: newAzimuth,
                status: 'save plans',
                plans: updatedPlans,
            };

        case 'SUCCESSFUL_SAVE_PLAN_TO_SERVER':
            const id = action.payload;
            const filteredArr = cloneDeep(state.changedPlanIds).filter((item) => item !== id);
            return { ...state, changedPlanIds: filteredArr };

        case 'CHECK_OUTSIDE_CHANGING_PLANS':
            if (!state.initialPlans) return state;
            const isPlansChangedOutside =
                action.payload.map((item) => item.id).join(',') !== state.initialPlans.map((item) => item.id).join(',');
            return { ...state, isPlansChangedOutside };

        case 'CHANGE_IS_GEOMAPPED':
            return { ...state, is_geomapped: action.payload, isSaveAllNeeded: true };

        case 'GET_DATA':
            let mapCenter, azimuth, plans, data;

            try {
                mapCenter = {
                    lat: action.payload.locationData.point.coordinates[0],
                    lng: action.payload.locationData.point.coordinates[1],
                };
            } catch (error) {
                mapCenter = state.mapCenter;
            }

            try {
                azimuth = action.payload.locationData.azimuth;
                if (!azimuth) {
                    azimuth = state.azimuth;
                }
            } catch (error) {
                azimuth = state.azimuth;
            }

            try {
                const { name, id } = action.payload.locationData;
                data = { name, plans: action.payload.plansData, locationId: id };
            } catch (error) {
                data = state.data;
            }

            try {
                const isAnchorPlanExists = action.payload.plansData.some((item) => item.is_anchor);
                plans = action.payload.plansData.map((item, i) => {
                    let opacity = 0;
                    const { geo_top_left, geo_top_right, geo_bottom_left, geo_bottom_right, is_anchor } = item;
                    let isAnchor = false;
                    if (isAnchorPlanExists) {
                        isAnchor = is_anchor;
                    } else {
                        isAnchor = i === 0 ? true : false;
                    }
                    if (isAnchor || String(item.id) === String(action.payload.currentPlanId)) {
                        opacity = 0.5;
                    }
                    const src = `${item.image}`;
                    const htmlImage = document.createElement('img');
                    htmlImage.src = src;
                    // htmlImage.crossOrigin = 'use-credentials';
                    htmlImage.alt = 'plan';
                    const corners = [geo_top_left, geo_top_right, geo_bottom_left, geo_bottom_right].map((item) => {
                        return item && item.coordinates ? convertFromCoordPointToLatLng(item.coordinates) : undefined;
                    });

                    const center = undefined;
                    return {
                        ...item,
                        htmlImage,
                        center,
                        corners,
                        isAnchor,
                        opacity,
                        color: generateColorForObjects(item.id),
                        editMode: 'lock',
                    };
                });
            } catch (error) {
                plans = [];
            }

            return {
                ...state,
                initialPlans: action.payload.plansData,
                initialData: { azimuth, plans },
                mapCenter,
                azimuth,
                plans,
                status: 'save plans',
                data,
                is_geomapped: action.payload.locationData.is_geomapped,
                currentPlanId: action.payload.currentPlanId,
            };

        case 'SHOW_PLANS':
            if (state.plans.length === 0) return state;
            // console.log(state.data.plans);
            // console.log(state.plans);
            // console.log(state.azimuth);

            let changed = cloneDeep(state.changedPlanIds);
            try {
                const plans = state.plans.map((item, i) => {
                    const src = `${item.image}`;
                    const htmlImage = document.createElement('img');
                    htmlImage.src = src;
                    // htmlImage.crossOrigin = 'use-credentials';
                    htmlImage.alt = 'plan';
                    const [sourcePlan] = state.data.plans.filter((plan) => plan.id === item.id);
                    let prevAzimuth = state.azimuth ? state.azimuth : 0;
                    let prev_scale = item.scale;
                    let corners;
                    if (!item.corners[0] || !item.corners[1] || !item.corners[2] || !item.corners[3] || prev_scale !== item.scale) {
                        corners = calculateCorners(state.map.current.leafletElement, item);
                        changed = Array.from(new Set([...changed, item.id]));
                        prevAzimuth = 0;
                        prev_scale = item.scale;
                    } else {
                        corners = item.corners;
                    }

                    if (prevAzimuth !== state.azimuth) {
                        const crs = map.current.leafletElement.options.crs;
                        const prevCorners = corners;
                        const delta = toRadians(state.azimuth - prevAzimuth);
                        corners = _rotateBy(prevCorners, delta, crs, map.current.leafletElement._zoom);
                    }
                    const center = calculateMidPoint(corners);

                    return {
                        ...item,
                        htmlImage,
                        prev_scale,
                        center,
                        corners,
                        color: generateColorForObjects(item.id),
                        editMode: 'lock',
                    };
                });

                return {
                    ...state,
                    changedPlanIds: changed,
                    showPlans: action.payload,
                    status: 'save plans',
                    plans,
                };
            } catch (error) {
                return state;
            }

        default:
            return state;
    }
}
