import React, { useContext, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import styled from 'styled-components/macro';
import arrowImage from './img/back.svg';
const Nav = styled.nav`
    display: flex;
    min-height: 30px;
    background-color: rgb(70, 184, 117);
    align-items: center;
`;

const List = styled.ul`
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
`;

const Item = styled.li`
    display: flex;
    align-items: center;
    margin: 0 10px;
`;

const BackButton = styled.div`
    background: url(${arrowImage}) center no-repeat;
    background-size: 130%;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 10px;
    /* background-color: white; */
    width: 26px;
    height: 26px;
    border-radius: 50%;
    opacity: 0.8;
    :hover {
        opacity: 1;
    }
`;

const activeClassName = 'active';

const NavItem = styled(NavLink).attrs({
    activeClassName,
})`
    color: whitesmoke;
    padding: 1px 10px;
    text-decoration: none;
    font-size: 18px;
    border-radius: 40px;

    &.${activeClassName} {
        font-weight: bold;
        background-color: whitesmoke;
        color: rgb(70, 184, 117);
    }
`;

export default function NavMenu({ navList, origin, ...props }) {
    const onClickReturnBack = () => {
        window.location.replace(origin);
    };
    const list = useMemo(() => {
        return navList.map((item) => {
            return (
                <Item key={item.title}>
                    <NavItem to={item.to} activeClassName="active">
                        {item.title}
                    </NavItem>
                </Item>
            );
        });
    }, navList);

    return (
        <Nav>
            <BackButton onClick={onClickReturnBack}></BackButton>
            <List>{list}</List>
        </Nav>
    );
}
