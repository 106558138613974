import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import { useHotkeys } from 'react-hotkeys-hook';

import cookie from 'react-cookies';
import styled from 'styled-components/macro';

import { parseQuery, removeParamsFromUrl } from './components/tools';
import GeoMap from './components/geo-mapping';
import OutdoorSpaces from './components/outdoor-spaces';
import OutdoorMapService from './components/outdoor-map-service';
import NavMenu from './components/nav-menu';
import CustomAlert from './components/alert';
import { urls as u } from './constants/urls';
import { maps } from './constants/maps';
import Modal from './components/modal';
import EngineeringModal from './components/modal/components/EngineeringModal';

const navHeight = '40px';

export const GlobalContext = React.createContext();

const App = React.memo(() => {
    const [token, setToken] = useState('');
    const [alert, toggleAlert] = useState({ open: false, text: '' });
    const [origin, setOrigin] = useState('');
    const [query, setQuery] = useState({});
    const [backEnd, setBackEnd] = useState({});
    const [urls, setUrls] = useState({});
    const [showMapService, setShowMapService] = useState(false);
    const [navList, setNavList] = useState([{ to: '/geo-mapping/', title: 'Geo Mapping' }]);
    const [modalStatus, setModalStatus] = useState({ show: false });

    useHotkeys('shift+d+o', () => setModalStatus({ show: true }));

    useEffect(() => {
        const newLocalStorageUrls = {};
        Object.keys(u).forEach((key) => {
            newLocalStorageUrls[key] = { checked: false, url: u[key] };
        });
        setBackEnd(newLocalStorageUrls);
        setUrls(newLocalStorageUrls);

        let urls = newLocalStorageUrls;

        let back = localStorage.getItem('urls');

        if (back !== null) {
            setBackEnd(() => JSON.parse(back));
            const backEnd = JSON.parse(back);
            const urls = {};
            Object.keys(u).forEach((key) => {
                if (backEnd[key].checked) {
                    urls[key] = { ...backEnd[key] };
                } else {
                    urls[key] = { ...newLocalStorageUrls[key] };
                }
            });
            setUrls(() => urls);
        } else {
            localStorage.setItem('urls', JSON.stringify(newLocalStorageUrls));
            urls = newLocalStorageUrls;
            setUrls(() => urls);
        }

        const query = parseQuery(window.location.search);
        setQuery((prev) => query);
        const myToken = query.token ? query.token : cookie.load('xtoken');

        const origin = query.origin;
        setOrigin(() => origin);

        if ((!myToken || myToken === 'undefined') && urls) {
            // console.log('document.location.href', document.location.href);
            window.location.replace(`${urls.AUTH_URL.url}?next=` + encodeURIComponent(document.location.href), '_blank');
        }
        setToken((prev) => myToken);
        if (token) {
            cookie.save('xtoken', token, { path: '/' });
        }
        window.history.pushState(null, null, removeParamsFromUrl(window.location.href, ['token', 'origin']));
    }, []);

    const getMaps = async (token) => {
        const url = `https://admin.client.getshopster.net/outdoor/map_options/`;
        const headers = {
            'x-token': token,
        };
        const response = await axios.get(url, { headers });
        // setMaps((prev) => response.data);
    };

    const getCurrent = async (token) => {
        const url = `https://admin.client.getshopster.net/accounts/current`;
        const headers = {
            'x-token': token,
        };
        const response = await axios.get(url, { headers });
        if (response.data.options.includes('role:superuser')) {
            setShowMapService(true);
            setNavList([
                { to: '/geo-mapping/', title: 'Geo Mapping' },
                // { to: '/spaces/', title: 'Outdoor Spaces' },
                // { to: '/outdoor-map-service/', title: 'Outdoor Map Service' }
            ]);
        } else {
            setNavList([
                { to: '/geo-mapping/', title: 'Geo Mapping' },
                // { to: '/spaces/', title: 'Outdoor Spaces' }
            ]);
        }
    };

    const closeModal = () => {
        setModalStatus({ show: false });
    };

    const onModalOKClick = (options) => {
        localStorage.setItem('urls', JSON.stringify(options));
        window.location.reload();
    };

    return (
        <Router>
            {navList && Object.keys(urls).length !== 0 && (
                <GlobalContext.Provider value={{ token, maps, alert, toggleAlert, urls, origin }}>
                    <Container>
                        <Modal open={modalStatus.show} closeModal={closeModal} disableBackdropClick={false}>
                            <EngineeringModal modalData={backEnd} onOKClick={onModalOKClick} />
                        </Modal>
                        <CustomAlert />
                        <NavMenu navList={navList} origin={origin} />
                        <Main>
                            <Route path="/geo-mapping/" render={() => <GeoMap />} />
                            {/* <Route path="/spaces/" render={() => <OutdoorSpaces />} />
                            {showMapService && <Route path="/outdoor-map-service/" render={() => <OutdoorMapService />} />} */}
                        </Main>
                    </Container>
                </GlobalContext.Provider>
            )}
        </Router>
    );
});

export default App;

const Container = styled.div`
    display: grid;
    grid-template-rows: ${navHeight} 1fr;
`;

const Main = styled.main`
    height: ${() => `calc(100vh - ${navHeight})`};
`;
