import React, { useContext, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import context from '../../context';
import { GlobalContext } from '../../../../App';

import { deleteData, getData } from '../../API';

const DeleteRegionWithPopulation = React.memo(({ ...props }) => {
  const { state, dispatch } = useContext(context);
  const { token } = useContext(GlobalContext);
  const { overlayModal } = state;

  useEffect(() => {
    if (!props.OKPressed) return;
    makeDeleteRequest();
  }, [props.OKPressed]);

  const makeDeleteRequest = async () => {
    const url = `https://outdoor-maps.getshopster.net/regions/delete_region_with_population/${overlayModal.options.region_id}/`;

    const response = await deleteData(token, url, dispatch);
    if (response.success) {
      dispatch({
        type: 'toggleOverlayModal',
        payload: { show: false }
      });
      dispatch({
        type: 'resetPolygonToCreate'
      });
      getData(
        token,
        `https://outdoor-maps.getshopster.net/regions/region_groups/`,
        'getRegionGroups',
        dispatch
      );
    } else {
      console.log(response.error);
    }
  };

  return (
    <Container>
      <Label>{`Do You Really want to Delete Region ${overlayModal.options.region_name}`}</Label>
    </Container>
  );
});

export default DeleteRegionWithPopulation;

const Container = styled.div`
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = React.memo(styled.label.attrs(props => {
  return {
    direction: !props.direction ? 'column' : props.direction,
    align: !props.direction ? 'flex-start' : 'center',
    cursor: !props.direction ? 'auto' : 'pointer'
  };
})`
  cursor: ${p => p.cursor};
  padding: 10px;
  margin: 5px 0;
  font-size: 14px;
  color: #1e90ff;
  display: flex;
  flex-direction: ${p => p.direction};
  align-items: ${p => p.align};
  width: 100%;
  /* border: 1px solid #777; */
  border-radius: 10px;
  user-select: none;
`);
