import React from 'react';
import styled from 'styled-components/macro';

const RegionGroupsDetails = React.memo(({ diameter = 20, marginRight = 'auto', ...props }) => {
  return <ColorDot color={props.color} diameter={diameter} marginRight={marginRight}></ColorDot>;
});

export default RegionGroupsDetails;

const ColorDot = styled.div`
  margin-right: ${p => p.marginRight};
  width: ${p => p.diameter + 'px'};
  height: ${p => p.diameter + 'px'};
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${p => p.color};
`;
