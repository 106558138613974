import React, { useContext, useMemo } from 'react';
import context from '../../context';
import styled from 'styled-components/macro';

import PlDetails from './pl_details';
import RegionGroupsDetails from './region_groups_details';
import MallsDetails from './malls_details';

const Container = styled.div`
  box-sizing: border-box;
  flex-shrink: 0;
  height: calc(100%);
  background-color: #eee;
  display: flex;
  flex-direction: column;
`;

const DetailsPanel = React.memo(({ ...props }) => {
  const { state, dispatch } = useContext(context);
  const { layers, regionGroups } = state;

  const activeLayer = useMemo(() => {
    return layers.filter(item => item.active)[0];
  }, [layers]);

  const details = useMemo(() => {
    if (!activeLayer) return null;
    switch (activeLayer.type) {
      case 'project_locations':
        return <PlDetails></PlDetails>;
      case 'region_groups':
        return <RegionGroupsDetails></RegionGroupsDetails>;
      case 'malls':
        return <MallsDetails />;

      default:
        return null;
    }
  }, [activeLayer, regionGroups]);

  return <Container>{details}</Container>;
});

export default DetailsPanel;
