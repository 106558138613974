import React, { useContext, useMemo } from 'react';
import styled from 'styled-components/macro';

const ListItemText = React.memo(props => {
  return <Text onClick={props.onClick}>{props.children}</Text>;
});

export default ListItemText;

const Text = styled.div`
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  margin-left: 10px;
`;
