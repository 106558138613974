import React from 'react';
import styled from 'styled-components/macro';
import Button from '@material-ui/core/Button';

const ReloadPage = () => {
    const onClick = () => {
        window.location.reload();
    };
    return (
        <Wrapper>
            <h3>Page reload needed!</h3>
            <Footer>
                <Button variant="contained" color="primary" onClick={onClick}>
                    Reload
                </Button>
            </Footer>
        </Wrapper>
    );
};

export default ReloadPage;

const Wrapper = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    height: 300px;
    background-color: whitesmoke;
`;
const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: auto;
`;
