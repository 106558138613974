import React, { useContext, useMemo, useCallback, useState } from 'react';
import context from '../context';
import { GlobalContext } from '../../../App';
import styled, { keyframes } from 'styled-components/macro';
import Button from './button';

export default React.memo(() => {
    const [typeOfRouter, setTypeOfRouter] = useState('adSpaces');
    const [typeOfData, setTypeOfData] = useState('CSV');
    const { state, dispatch } = useContext(context);
    const { token } = useContext(GlobalContext);
    const handleClosePolygonModal = e => {
        dispatch({
            type: 'togglePolygonsCatalogModal'
        });
    };
    const typeOfDataButtonClick = () => {
        if (typeOfData === 'CSV') {
            setTypeOfData('JSON');
        } else {
            setTypeOfData('CSV');
        }
    };
    const typeOfRouterButtonClick = () => {
        if (typeOfRouter === 'adSpaces') {
            setTypeOfRouter('iPoints');
        } else {
            setTypeOfRouter('adSpaces');
        }
    };
    return (
        <Overlay>
            <Title>{`${typeOfRouter} | ${typeOfData}`}</Title>
            <AreaContainer>
                <TextArea value={state.polygonsCatalogModal.polygons[typeOfRouter][typeOfData]} readOnly />
                <Buttons>
                    <Button onClick={typeOfRouterButtonClick}>
                        {typeOfRouter === 'adSpaces' ? 'show iPoints' : 'show adSpaces'}
                    </Button>
                    <Button onClick={typeOfDataButtonClick}>{typeOfData === 'CSV' ? 'show JSON' : 'show CSV'}</Button>
                    <Button onClick={handleClosePolygonModal}>Close</Button>
                </Buttons>
            </AreaContainer>
        </Overlay>
    );
});

const appearence = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const TextArea = styled.textarea`
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: whitesmoke;
    outline: none;
    border: none;
`;

const Overlay = React.memo(styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 50px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5002;
    background-color: rgba(0, 0, 0, 0.5);
    flex-direction: column;
    animation: ${appearence} 0.3s ease-in-out;
`);

const Title = React.memo(styled.h4`
    padding: 0;
    margin: 0;
    list-style: none;
    /* font-family: HelveticaLight; */
    font-size: 18px;
    color: whitesmoke;
`);

const AreaContainer = React.memo(styled.div`
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
`);

const Buttons = React.memo(styled.div`
    width: 100%;
    display: grid;
    grid-gap: 5px;
    padding: 20px;
    margin: 0;
    margin-top: auto;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    bottom: 0;
`);

const List = React.memo(styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
`);

const Item = React.memo(styled.li`
    padding: 5px 0;
    margin: 0;
    list-style: none;
`);

const Label = React.memo(styled.label`
    padding: 10px 0;
    margin: 0;
    display: flex;
    flex-direction: column;
`);

const Input = React.memo(styled.input`
    padding: 5px;
    margin: 0;
    margin-top: 5px;
    display: flex;
    font-size: 16px;
    outline: none;
    border: 1px solid #1e90ff;
    border-radius: 5px;
    pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
    background-color: ${p => (p.disabled ? '#ccc' : '#fff')};
    color: ${p => (p.disabled ? '#999' : '#000')};
`);

const Select = styled.select`
    margin: 0;
    margin-top: 5px;
    display: flex;
    font-size: 16px;
`;

const Option = styled.option`
    padding: 5px;
    margin: 0;
    margin-top: 5px;
    display: flex;
`;
