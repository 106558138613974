import React, { useContext, useMemo } from 'react';
import context from '../context';
import styled from 'styled-components/macro';

const Container = styled.div`
  box-sizing: border-box;
  flex-shrink: 0;
  overflow: auto;
`;

const Field = styled.p`
  box-sizing: border-box;
  flex-shrink: 0;
  color: #666;
  margin: 0;
  padding: 0;
`;

const Title = styled.h4`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: normal;
  margin: 20px 0 10px 0;
  border-bottom: 1px solid #777;
  padding-bottom: 5px;
  color: #1e90ff;
`;

const List = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const PointType = styled.div`
  margin-bottom: 5px;
  margin-top: 10px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  user-select: none;
  background-color: ${props => (props.type === 'adSpaces' ? '#c4cdff' : '#ffe2be')};
  pointer-events: ${p => (p.clickable ? 'auto' : 'none')};
  opacity: ${p => (!p.clickable && p.selected ? 1 : !p.clickable && !p.selected ? 0.3 : 1)};
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
`;

const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${props => (props.selected ? 'green' : 'black')};
`;

const LocationDetails = React.memo(() => {
  const { state, dispatch } = useContext(context);
  const { selectedPL, innerData, editModal } = state;

  const title = selectedPL[0] ? `Location ${selectedPL[0]} details` : 'No location selected';

  const onItemClick = (item, typeOfRouter) => e => {
    const data = { routerInfo: { id: item.id }, typeOfRouter };
    dispatch({
      type: 'handleEditRouterClick',
      payload: { data }
    });
  };

  const details = () => {
    const result = {};
    Object.keys(innerData).forEach(pointType => {
      result[pointType] = [];
      if (selectedPL.length === 0) return null;
      innerData[pointType].forEach(point => {
        if (point.project_location === selectedPL[0]) {
          const info = {
            // type: pointType
          };
          if (point.marker) {
            info.marker = point.marker;
          }
          info.id = point.id;
          info[point.name ? 'name' : 'mac'] = point.name ? point.name : point.mac ? point.mac : '';

          result[pointType].push(info);
        }
      });
    });

    return Object.keys(result).map(pointType => {
      return (
        <div key={`pointType-${pointType}`}>
          <PointType>{pointType}</PointType>
          {result[pointType].map(item => {
            const selected = item.id === editModal.id;
            return (
              <Item
                key={`detail - ${item.id}`}
                type={pointType}
                clickable={!editModal.show}
                selected={selected}
                onClick={onItemClick(item, pointType)}
              >
                {Object.keys(item).map(field => {
                  return (
                    <ItemTitle key={`${field} - ${item.id}`}>
                      <Field>{field}</Field>
                      {`: ${item[field]}`}
                    </ItemTitle>
                  );
                })}
              </Item>
            );
          })}
        </div>
      );
    });
  };

  return useMemo(
    () => (
      <Container>
        <Title>{title}</Title>
        <List>{details()}</List>
      </Container>
    ),
    [selectedPL, innerData, editModal]
  );
});

export default LocationDetails;
