import React, { useContext } from 'react';
import context from '../context';
import styled from 'styled-components/macro';

const Overlay = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1001;
    /* background-color: rgba(255, 0, 0, 0.5); */
    background-color: transparent;
    display: ${props => (props.show ? 'flex' : 'none')};
`;

const Container = styled.div`
    max-width: 300px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    left: ${props => props.x + 'px'};
    top: ${props => props.y + 'px'};
    z-index: 1002;
    background-color: #ddd;
`;

const MenuList = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
`;

const Item = styled.li`
    padding: 5px 0;
    margin: 0;
    list-style: none;
    cursor: pointer;
    font-size: 12px;
    display: ${props => (props.show ? 'flex' : 'none')};
`;

const ContextMenu = React.memo(() => {
    const { state, dispatch } = useContext(context);
    const { contextMenu, editModal } = state;

    const onEditClick = data => e => {

        dispatch({
            type: 'handleCloseContextMenu'
        });
        if (state.selectedPL.length === 0) {
            dispatch({
                type: 'selectPL',
                id: data.routerInfo.project_location
            });
        }
        dispatch({
            type: 'aimOnPoint',
            center: { lat: data.routerInfo.latitude, lng: data.routerInfo.longitude }
        });
        dispatch({
            type: 'setQuery',
            pl_id: data.routerInfo.project_location
        });
        dispatch({
            type: 'handleEditRouterClick',
            payload: { data }
        });
    };

    const onCopyToClipboard = data => e => {
        e.preventDefault();
        const { latitude, longitude } = data.routerInfo;
        navigator.clipboard.writeText(`${latitude}, ${longitude}`);
    };

    const onSelectPL = data => e => {
        e.preventDefault();
        // console.log(data);
        dispatch({
            type: 'selectPL',
            id: data.routerInfo.project_location
        });
        dispatch({
            type: 'aimOnPoint',
            center: { lat: data.routerInfo.latitude, lng: data.routerInfo.longitude }
        });
        dispatch({
            type: 'setQuery',
            pl_id: data.routerInfo.project_location
        });
    };

    const onOverlayClick = e => {
        dispatch({
            type: 'handleCloseContextMenu'
        });
    };

    try {
        let title = '';
        if (contextMenu.typeOfRouter === 'iPoints') {
            title = `iPoint | ${contextMenu.routerInfo.marker} | ${contextMenu.routerInfo.id}`;
        } else if (contextMenu.typeOfRouter === 'adSpaces') {
            title = `adSpace | ${contextMenu.routerInfo.name}`;
        }

        return (
            <Overlay show={contextMenu.show} onClick={onOverlayClick}>
                <Container x={contextMenu.x} y={contextMenu.y}>
                    <MenuList>
                        <Item
                            show={contextMenu.show && !editModal.show}
                            onClick={onEditClick(contextMenu)}
                        >{`Edit ${title}`}</Item>
                        <Item show={true} onClick={onSelectPL(contextMenu)}>{`Select PL`}</Item>
                        <Item show={true} onClick={onCopyToClipboard(contextMenu)}>{`Copy coordinates`}</Item>
                    </MenuList>
                </Container>
            </Overlay>
        );
    } catch (error) {
        return null;
    }
});

export default ContextMenu;
