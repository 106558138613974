import React, { useContext, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Slider from '@material-ui/core/Slider';
// import Slider from 'react-rangeslider';
import styled from 'styled-components/macro';
import Context from '../context';
import Modes from './modes';
import Anchor from './anchor';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        display: 'flex',
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function Plans() {
    const classes = useStyles();
    const { state, dispatch } = useContext(Context);

    const { changedPlanIds, plans, data, currentPlanId, isAzimuthChanged } = state;

    // console.log(plans);

    const onOpacityChange = (id) => (event, value) => {
        event.stopPropagation();
        dispatch({
            type: 'OPACITY_CHANGE',
            payload: { id, value },
        });
    };

    const onSliderClick = (event) => {
        event.stopPropagation();
    };

    const structure = useMemo(() => {
        const prevResult = {};
        const result = {};
        plans
            .sort((a, b) => b.floor - a.floor)
            .forEach((plan) => {
                if (plan.isAnchor || currentPlanId === plan.id) {
                    prevResult[String(plan.floor)] = { ...prevResult[String(plan.floor)], main: plan };
                } else {
                    const other =
                        prevResult[String(plan.floor)] && prevResult[String(plan.floor)].other ? prevResult[String(plan.floor)].other : [];
                    other.push(plan);
                    prevResult[String(plan.floor)] = { ...prevResult[String(plan.floor)], other };
                }
            });

        Object.keys(prevResult).forEach((floor) => {
            if (!prevResult[floor].main) {
                const other = prevResult[floor].other;
                const main = other.shift();
                result[floor] = { main, other };
            } else {
                const other = prevResult[floor].other || [];
                const main = prevResult[floor].main;
                result[floor] = { main, other };
            }
        });
        // console.log(result);
        return result;
    }, [plans]);

    const onWrapperClick = (e) => {
        e.stopPropagation();
    };

    return data && plans ? (
        <PlansContainer>
            <PlansTitle>{data.name}</PlansTitle>
            {Object.keys(structure)
                .sort((a, b) => Number(b) - Number(a))
                .map((floor, i) => {
                    const actualPlan = structure[floor].main;
                    const otherPlans = structure[floor].other;
                    console.log('>>>', otherPlans);
                    const expandIcon = otherPlans.length !== 0 ? <ExpandMoreIcon /> : null;
                    return (
                        <Accordion key={`actualPlan__${actualPlan.id}`}>
                            <StAccordionSummary expandIcon={expandIcon}>
                                <Wrapper onClick={onWrapperClick}>
                                    <Anchor plan={actualPlan} />
                                    <Floor bgColor={actualPlan.color}>{actualPlan.floor}</Floor>
                                    <Div>
                                        <Slider
                                            min={0}
                                            max={100}
                                            value={actualPlan.opacity * 100.0}
                                            onChange={onOpacityChange(actualPlan.id)}
                                            onClick={onSliderClick}
                                            aria-labelledby="continuous-slider"
                                        />
                                    </Div>
                                    <Modes plan={actualPlan} changedPlanIds={changedPlanIds} isAzimuthChanged={isAzimuthChanged} />
                                </Wrapper>
                            </StAccordionSummary>
                            {otherPlans.length !== 0 ? (
                                <StAccordionDetails>
                                    {otherPlans.map((plan) => {
                                        return (
                                            <InnerWrapper key={`plan))${plan.id}`}>
                                                <Anchor plan={plan} />
                                                <Floor bgColor={plan.color}>{plan.floor}</Floor>
                                                <Div>
                                                    <Slider
                                                        min={0}
                                                        max={100}
                                                        value={plan.opacity * 100.0}
                                                        onChange={onOpacityChange(plan.id)}
                                                        aria-labelledby="continuous-slider"
                                                    />
                                                </Div>
                                                <Modes plan={plan} changedPlanIds={changedPlanIds} isAzimuthChanged={isAzimuthChanged} />
                                            </InnerWrapper>
                                        );
                                    })}
                                </StAccordionDetails>
                            ) : null}
                        </Accordion>
                    );
                })}
        </PlansContainer>
    ) : null;
}

const PlansContainer = styled.div`
    /* margin: 5px 0 !important; */
    /* min-height: auto !important;
    .Mui-expanded {
        min-height: auto !important;
    } */
`;

const PlansTitle = styled.h4`
    margin: 10px 0 10px 0;
    font-size: 18px !important;
    font-weight: 700 !important;
    padding: 0;
`;

const Div = styled.div`
    width: 70px;
    flex: 1;
    display: flex;
    align-items: center;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    /* padding-left: 10px; */
`;
const InnerWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-left: 30px;
`;

const StAccordionSummary = styled(AccordionSummary)`
    /* background-color: red !important; */
    .MuiAccordionSummary-content {
        margin: 0 !important;
    }
    .MuiIconButton-root {
        padding: 0 !important;
    }
`;

const StAccordionDetails = styled(AccordionDetails)`
    overflow: auto !important;
    padding: 0 !important;
    flex-direction: column;
    .MuiIconButton-root {
        flex-direction: column;
        overflow: auto !important;
        padding: 0 !important;
    }
`;

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const Item = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    margin: 5px 0;
    border: 1px solid #777;
    border-radius: 5px;
`;

const Floor = styled.div`
    width: 24px;
    height: 24px;
    background-color: ${(props) => props.bgColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    font-size: 15px;
    font-weight: 700;
    margin-right: 10px;
`;
