import React, { useMemo, useState, useEffect, useCallback } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components/macro';

const EngineeringModal = React.memo(({ modalData, onOKClick, ...props }) => {
    const [localBackend, setLocalBackend] = useState(modalData);


    const onUrlChange = (key) => (event) => {
        if (event.target.value !== undefined) {
            const loc = { ...localBackend, [key]: { checked: localBackend[key].checked, url: event.target.value } };
            setLocalBackend({ ...loc });
        }
    };

    const onCheckChange = (key: string) => (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
        checked?: boolean | undefined
    ) => {
        if (checked !== undefined) {
            const loc = { ...localBackend, [key]: { checked, url: localBackend[key].url } };
            setLocalBackend({ ...loc });
        }
    };

    const handleOKClick = (options: typeof modalData) => () => {
        onOKClick(options);
    };

    return (
        <MainWrapper>
            <h3>Change backend urls</h3>
            <Container>
                {Object.keys(localBackend).map((key, i) => {
                    const checked = localBackend[key].checked;
                    return (
                        <Wrapper key={`${i} - ${key}`}>
                            <StCheckbox checked={checked} onChange={onCheckChange(key)} />
                            <StTextField label={key} disabled={!checked} value={localBackend[key].url || ''} onChange={onUrlChange(key)} />
                        </Wrapper>
                    );
                })}
            </Container>
            <Footer>
                <Button variant="contained" color="primary" onClick={handleOKClick({ ...localBackend })}>
                    OK
                </Button>
            </Footer>
        </MainWrapper>
    );
});

export default EngineeringModal;

const Footer = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
`;

const MainWrapper = styled.div`
    padding: 10px;
    display: flex;
    width: 600px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: whitesmoke;
`;

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-end;
    margin-bottom: 10px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
`;

const StTextField = styled(TextField)`
    width: 100% !important;
    padding-bottom: 9px !important;
    margin-left: 10px;
    textarea {
        max-height: 300px !important;
        overflow: auto !important;
    }
`;

const StCheckbox = styled(Checkbox)`
    margin-bottom: 5px;
`;
