import React, { useContext } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { GlobalContext } from '../../App';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomAlert = ({ ...props }) => {
    const { alert, toggleAlert } = useContext(GlobalContext);
    const { autoHideDuration = 2000, severity = 'success', text = '', open = false } = alert;

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        toggleAlert({ open: false });
    };

    return (
        <Snackbar
            open={open}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
        >
            {open ? <Alert severity={severity}>{text}</Alert> : null}
        </Snackbar>
    );
};

export default CustomAlert;
