import React, { useContext, useMemo } from 'react';
import context from '../../context';
import styled from 'styled-components/macro';
import EyeIcon from '../eye_icon';
import ShowNameButton from '../show_name_button';
import ListItem from '../list_item';
import ListItemText from '../list_item_text';
import ColorDot from '../color_dot';
import Button from '../button';

const RegionGroupsDetails = React.memo(({ ...props }) => {
  const { state, dispatch } = useContext(context);
  const { regionGroups } = state;

  const onRegionGroupEyeClick = groupId => () => {
    dispatch({
      type: 'onRegionGroupEyeClick',
      payload: { groupId }
    });
  };

  const onRegionGroupClick = groupId => () => {
    // if (groupToEdit) {
    //   dispatch({
    //     type: 'resetGroupToEdit'
    //   });
    // }
    dispatch({
      type: 'resetPolygonToCreate'
    });
    dispatch({
      type: 'onRegionGroupClick',
      payload: { layerType: 'region_groups', groupId }
    });
  };

  const onShowNameClick = groupId => () => {
    dispatch({
      type: 'onShowNameClick',
      payload: { groupId }
    });
  };

  const toggleOverlayModal = (show, options) => e => {
    dispatch({
      type: 'toggleOverlayModal',
      payload: { show, options }
    });
  };

  const details = regionGroups.map((group, i) => {
    const isVisible = group.visible;
    return (
      <ListItem key={`${group.name} - ${i}`} isActive={group.active}>
        <ColorDot color={group.color}></ColorDot>
        <ListItemText
          onClick={onRegionGroupClick(group.id)}
        >{`${group.name} (${group.regions.length})`}</ListItemText>
        <ShowNameButton showNames={group.showNames} onClick={onShowNameClick(group.id)} />
        <EyeIcon isVisible={isVisible} marginLeft={0} onClick={onRegionGroupEyeClick(group.id)} />
      </ListItem>
    );
  });

  return (
    <Container>
      {details}
      <Button
        width={'100%'}
        onClick={toggleOverlayModal(true, {
          type: 'create_regions_group',
          title: 'Create new Regions Group'
        })}
      >
        {'Create New Regions Group'}
      </Button>
    </Container>
  );
});

export default RegionGroupsDetails;

const Container = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
