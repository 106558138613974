import React, { useContext, useMemo, useCallback, useEffect } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import axios from 'axios';

export default React.memo(
  ({
    width,
    disabled = false,
    theme,
    saveStatus,
    onClick,
    children,
    margin = 0,
    isPickCursorCoords
  }) => {
    const bgColors = useMemo(() => {
      if (disabled) {
        return {
          main: '#bbb'
        };
      }
      switch (theme) {
        case 'red':
          return {
            main: '#f51a21',
            hover: '#d0090f',
            active: '#a6070c'
          };

        default:
          return {
            main: '#1e90ff',
            hover: '#0074e4',
            active: '#005db6'
          };
      }
    }, [theme, disabled]);
    return (
      <Button
        bgColors={bgColors}
        width={width}
        disabled={disabled}
        saveStatus={saveStatus}
        isPickCursorCoords={isPickCursorCoords}
        onClick={onClick}
        margin={margin}
      >
        {children}
      </Button>
    );
  }
);

const Button = React.memo(styled.button`
  margin: ${p => p.margin};
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  cursor: pointer;
  /* border: 1px solid #777; */
  border-radius: 30px;
  width: ${props => props.width};
  min-width: 80px;
  height: 46px;
  font-size: 18px;
  pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
  outline: none;
  user-select: none;
  background-color: ${p => p.bgColors.main};
  &:hover {
    background-color: ${p => p.bgColors.hover};
  }
  &:active {
    background-color: ${p => p.bgColors.active};
  }
`);
