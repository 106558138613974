import React, { useContext } from 'react';
import context from '../context';
import styled from 'styled-components/macro';

const ListItem = React.memo(({ ...props }) => {
  const { state, dispatch } = useContext(context);
  return <Container isActive={props.isActive}>{props.children}</Container>;
});

export default ListItem;

const Container = styled.li`
  min-height: 40px;
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid #777;
  border-radius: 30px;
  background-color: ${p => (p.isActive ? '#ccc' : '#eee')};
  cursor: pointer;
  margin-bottom: 5px;
`;
