import React, { useContext, useMemo } from 'react';
import context from '../../context';
import styled from 'styled-components/macro';

import Button from '../button';

const MallsDetails = React.memo(({ ...props }) => {
  const { state, dispatch } = useContext(context);
  const { showMallsNames } = state;

  const toggleShowMallsNames = e => {
    dispatch({
      type: 'toggleShowMallsNames',
      payload: e.target.checked
    });
  };

  const onCreateNewMallClick = e => {
    dispatch({
      type: 'onCreatePolygon',
      payload: { mode: 'create', layerType: 'malls' }
    });
  };

  return (
    <Container>
      <Options>
        <Label direction={'row'}>
          <Input type={'checkbox'} checked={showMallsNames} onChange={toggleShowMallsNames}></Input>
          Show Malls Names
        </Label>
      </Options>
      <Button width={'100%'} onClick={onCreateNewMallClick}>
        {'Create New Mall'}
      </Button>
    </Container>
  );
});

export default MallsDetails;

const Container = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
const Options = styled.div`
  width: 100%;
  padding: 0 10px;
  border: 1px solid #777;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const Input = React.memo(styled.input.attrs(({ type, ...props }) => {
  let marginR = 0;
  let width = '100%';
  if (type === 'checkbox') {
    marginR = '10px';
    width = 'auto';
  }
  return {
    marginR,
    width
  };
})`
  padding: 5px;
  padding-left: 0;
  margin: 0;
  margin-right: ${p => p.marginR};
  font-size: 14px;
  display: flex;
  flex-direction: column;
  width: ${p => p.width} !important;
  min-height: 30px;
  border: none !important;
  border-bottom: 1px solid #1e90ff !important;
  background-color: transparent !important;
  :focus {
    outline: none !important;
  }
`);

const Label = React.memo(styled.label.attrs(props => {
  return {
    direction: !props.direction ? 'column' : props.direction,
    align: !props.direction ? 'flex-start' : 'center',
    cursor: !props.direction ? 'auto' : 'pointer'
  };
})`
  cursor: ${p => p.cursor};
  margin: 5px 0;
  font-size: 14px;
  color: #1e90ff;
  display: flex;
  flex-direction: ${p => p.direction};
  align-items: ${p => p.align};
  width: 100%;
  /* border: 1px solid #777; */
  border-radius: 10px;
  user-select: none;
  span {
    margin-bottom: 5px;
  }
`);
