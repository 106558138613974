import React, { useContext, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import context from '../../context';
import { GlobalContext } from '../../../../App';

import { postData, getData } from '../../API';

const CreateRegionsGroup = React.memo(({ ...props }) => {
  const { state, dispatch } = useContext(context);
  const { token } = useContext(GlobalContext);
  const [groupNameValue, setGroupNameValue] = useState('');
  const [intersectionsAllowed, setIntersectionsAllowed] = useState(true);
  const { overlayModal } = state;

  useEffect(() => {
    if (groupNameValue === '' || !props.OKPressed) return;
    makePostRequest();
  }, [props.OKPressed]);

  const makePostRequest = async () => {
    const url = 'https://outdoor-maps.getshopster.net/regions/create_region_group/';
    const data = {
      name: groupNameValue,
      intersection_allowed: intersectionsAllowed
    };
    const response = await postData(token, url, data, dispatch);
    if (response.success) {
      dispatch({
        type: 'toggleOverlayModal',
        payload: { show: false }
      });
      getData(
        token,
        `https://outdoor-maps.getshopster.net/regions/region_groups/`,
        'getRegionGroups',
        dispatch
      );
    } else {
      console.log(response.error);
    }
  };

  const onNameChange = e => {
    setGroupNameValue(e.target.value);
  };

  const onIntersectionsAllowedChange = e => {
    setIntersectionsAllowed(e.target.checked);
  };

  return (
    <Container>
      <Label>
        Regions Group Name
        <Input type={'text'} autoFocus value={groupNameValue} onChange={onNameChange}></Input>
      </Label>
      <Label direction={'row'}>
        <Input
          type={'checkbox'}
          checked={intersectionsAllowed}
          onChange={onIntersectionsAllowedChange}
        ></Input>
        Intersections Allowed
      </Label>
    </Container>
  );
});

export default CreateRegionsGroup;

const Container = styled.div`
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = React.memo(styled.input.attrs(({ type, ...props }) => {
  let marginR = 0;
  let width = '100%';
  if (type === 'checkbox') {
    marginR = '10px';
    width = 'auto';
  }
  return {
    marginR,
    width
  };
})`
  padding: 5px;
  margin: 0;
  margin-right: ${p => p.marginR};
  font-size: 14px;
  display: flex;
  flex-direction: column;
  width: ${p => p.width} !important;
  min-height: 30px;
  border: none !important;
  border-bottom: 1px solid #1e90ff !important;
  background-color: transparent !important;
  :focus {
    outline: none !important;
  }
`);

const Label = React.memo(styled.label.attrs(props => {
  return {
    direction: !props.direction ? 'column' : props.direction,
    align: !props.direction ? 'flex-start' : 'center',
    cursor: !props.direction ? 'auto' : 'pointer'
  };
})`
  cursor: ${p => p.cursor};
  padding: 10px;
  margin: 5px 0;
  font-size: 14px;
  color: #1e90ff;
  display: flex;
  flex-direction: ${p => p.direction};
  align-items: ${p => p.align};
  width: 100%;
  /* border: 1px solid #777; */
  border-radius: 10px;
  user-select: none;
`);
