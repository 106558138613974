import React, { useContext, useMemo } from 'react';
import context from '../../context';
import styled from 'styled-components/macro';
import LayersPanel from './layers_panel';
import DetailsPanel from '../details/details_panel';
import ScreenPlList from './screen_pl_list';

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 10px;
  width: 300px;
  flex-shrink: 0;
  height: calc(100%);
  background-color: #eee;
  display: grid;
  grid-template-rows: auto auto 10fr;
  grid-gap: 10px;
`;

const Divider = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 1px;
  /* background-color: #777; */
  margin: 5px 0;
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ZOOM_TO_SHOW_SCREEN_PL_LIST = 15;

const ControlPanel = React.memo(() => {
  const { state, dispatch } = useContext(context);
  const { polygonToCreate, currentZoom, layers } = state;

  const isPlLayerVisible = layers.filter(layer => layer.type === 'project_locations')[0].visible;

  const onOverlayClick = e => {
    e.stopPropagation();
  };

  return (
    <Container>
      {polygonToCreate && <Overlay onClick={onOverlayClick} />}
      <LayersPanel />
      <Divider />
      <DetailsPanel />
      <Divider />
      {isPlLayerVisible && currentZoom >= ZOOM_TO_SHOW_SCREEN_PL_LIST ? <ScreenPlList /> : null}
    </Container>
  );
});

export default ControlPanel;
