import React, { useContext, useMemo, useCallback, useEffect } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import axios from 'axios';

export default React.memo(({ width, disabled = false, saveStatus, onClick, children, isPickCursorCoords }) => {
    return (
        <Button
            width={width}
            disabled={disabled}
            saveStatus={saveStatus}
            isPickCursorCoords={isPickCursorCoords}
            onClick={onClick}
        >
            {children}
        </Button>
    );
});

const Button = React.memo(styled.button`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: center;
    cursor: pointer;
    /* border: 1px solid #777; */
    border-radius: 5px;
    width: ${props => props.width};
    height: 40px;
    font-size: 14px;
    pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
    outline: none;
    user-select: none;
    background-color: ${props =>
        props.saveStatus === 'sucсess' || props.isPickCursorCoords
            ? '#2e8b57'
            : props.saveStatus === 'error'
            ? 'rgb(254, 174, 108)'
            : props.disabled
            ? '#ccc'
            : '#1e90ff'};
    &:hover {
        background-color: #0074e4;
    }
    &:active {
        background-color: #005db6;
    }
`);
