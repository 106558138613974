import React, { useContext, useMemo } from 'react';
import context from '../context';
import styled from 'styled-components/macro';
import eyeImage from '../img/eye.svg';

const Layer = React.memo(({ layer, ...props }) => {
  const { state, dispatch } = useContext(context);
  const { activeLayerId } = state;

  const onClick = e => {
    dispatch({
      type: 'onTestClick',
      payload: true
    });
  };

  const onLayerClick = id => e => {
    dispatch({
      type: 'onLayerClick',
      payload: { id }
    });
  };

  const isActive = activeLayerId === layer.id;

  return useMemo(
    () => (
      <LayerContainer isActive={isActive} onClick={onLayerClick(layer.id)}>
        <EyeIcon />
        {layer.name}
      </LayerContainer>
    ),
    [isActive]
  );
});

export default Layer;

const LayerContainer = styled.div`
  min-height: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #777;
  background-color: ${p => p.isActive ? '#ccc' : '#eee'};
  /* color: ${p => p.isActive ? '#fff' : '#000'}; */
  cursor: pointer;
`;

const EyeIcon = styled.div`
  height: 20px;
  width: 20px;
  background: url(${eyeImage}) center no-repeat;
  background-size: 80%;
  border: 1px solid #777;
  margin-right: 10px;
  margin-left: 5px;
`;
