import React, { useContext, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import context from '../../context';

const DeleteMall = React.memo(({ ...props }) => {
  const { state, dispatch } = useContext(context);
  const { overlayModal } = state;

  useEffect(() => {
    if (!props.OKPressed) return;
  }, [props.OKPressed]);

  return (
    <Container>
      <Label>{`${overlayModal.options.error}`}</Label>
      <Label>{`${overlayModal.options.error.response.data.error}`}</Label>
    </Container>
  );
});

export default DeleteMall;

const Container = styled.div`
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = React.memo(styled.label.attrs(props => {
  return {
    direction: !props.direction ? 'column' : props.direction,
    align: !props.direction ? 'flex-start' : 'center',
    cursor: !props.direction ? 'auto' : 'pointer'
  };
})`
  cursor: ${p => p.cursor};
  padding: 10px;
  margin: 5px 0;
  font-size: 14px;
  color: #1e90ff;
  display: flex;
  flex-direction: ${p => p.direction};
  align-items: ${p => p.align};
  width: 100%;
  /* border: 1px solid #777; */
  border-radius: 10px;
  user-select: none;
`);
