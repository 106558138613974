import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import context from '../context';

const Search = React.memo(({ searchValue, ...props }) => {
    const { state, dispatch } = useContext(context);

    const handleSearch = e => {
        e.preventDefault();
        dispatch({
            type: 'handleSearch',
            payload: e.target.value
        });
    };

    return (
        <SearchContainer>
            <input
                value={state.searchValue}
                onChange={handleSearch}
                type="text"
                placeholder={'Search'}
                autoFocus="autofocus"
            />
        </SearchContainer>
    );
});

export default Search;

const SearchContainer = styled.div`
    margin: 20px 0;
    width: 100%;
    input {
        font-size: 14px;
        width: 100%;
        padding: 6px 12px;
        color: #555;
        border: 1px solid #1e90ff;
        border-radius: 5px;
        background-color: #fff;
        font-size: 14px;
        margin-bottom: 10px;
        padding-right: 28px;
        color: #848484;
        outline: none
    }
`;
