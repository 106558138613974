import React, { useContext, useMemo } from 'react';
import context from '../../context';
import styled from 'styled-components/macro';
import Layer from './layer';

const Container = styled.div`
  box-sizing: border-box;
  flex-shrink: 0;
  height: calc(100%);
  background-color: #eee;
  display: flex;
  flex-direction: column;
`;

const LayersPanel = React.memo(({ ...props }) => {
  const { state, dispatch } = useContext(context);
  const { layers } = state;

  return useMemo(
    () => (
      <Container>
        {layers.map(layer => {
          return <Layer layer={layer} key={`${layer.name}-${layer.id}`} />;
        })}
      </Container>
    ),
    [layers]
  );
});

export default LayersPanel;
