import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import context from '../../context';

import Button from '../button';
import CreateRegionsGroup from './create_regions_group';
import DeleteRegionsGroup from './delete_regions_group';
import DeleteRegion from './delete_region_with_population';
import DeleteMall from './delete_mall';
import ServerError from './server_error';

const OverlayModal = React.memo(({ ...props }) => {
  const { state, dispatch } = useContext(context);
  const { overlayModal } = state;
  const [OKPressed, setOKPressed] = useState(false);

  const toggleOverlayModal = show => e => {
    dispatch({
      type: 'toggleOverlayModal',
      payload: { show }
    });
  };

  const main = useMemo(() => {
    switch (overlayModal.options.type) {
      case 'create_regions_group':
        return <CreateRegionsGroup OKPressed={OKPressed}></CreateRegionsGroup>;
      case 'delete_region_group':
        return <DeleteRegionsGroup OKPressed={OKPressed}></DeleteRegionsGroup>;
      case 'delete_region_with_population':
        return <DeleteRegion OKPressed={OKPressed}></DeleteRegion>;
      case 'delete_mall':
        return <DeleteMall OKPressed={OKPressed}></DeleteMall>;
      case 'server_error':
        return <ServerError OKPressed={OKPressed}></ServerError>;

      default:
        return null;
    }
  }, [overlayModal, OKPressed]);

  const onContainerClick = e => {
    e.stopPropagation();
  };

  const onOKClick = e => {
    setOKPressed(true);
    setTimeout(() => {
      setOKPressed(false);
    }, 100);
  };

  return (
    <Overlay onClick={toggleOverlayModal(false)}>
      <Container width={600} height={400} onClick={onContainerClick}>
        {overlayModal.options.title ? <Title>{overlayModal.options.title}</Title> : null}
        {main}
        <Footer>
          <Button onClick={toggleOverlayModal(false)}>Cancel</Button>
          <Button onClick={onOKClick} margin={'0 0 0 5px'}>
            OK
          </Button>
        </Footer>
      </Container>
    </Overlay>
  );
});

export default OverlayModal;

const Overlay = styled.section`
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  left: 0;
  top: 0;
`;

const Container = styled.div`
  box-sizing: border-box;
  padding: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: ${p => -p.width / 2 + 'px'};
  margin-top: ${p => -p.height / 2 + 'px'};
  width: ${p => p.width + 'px'};
  height: ${p => p.height + 'px'};
  background-color: whitesmoke;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
`;

const Footer = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const Title = React.memo(styled.h4`
  padding: 0;
  margin: 0;
  list-style: none;
  /* font-family: HelveticaLight; */
  font-size: 18px;
  color: #1e90ff;
`);
