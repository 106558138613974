import React from 'react';
import styled from 'styled-components/macro';
import eyeImage from '../img/eye.svg';

const ShowNameButton = React.memo(({ ...props }) => {
  return <Button showNames={props.showNames} onClick={props.onClick}>T</Button>;
});

export default ShowNameButton;

const Button = styled.div`
  opacity: ${p => (p.showNames ? 1 : 0.3)};
  height: 20px;
  width: 20px;
  background-size: 80%;
  border: 2px solid #777;
  /* border-radius: 50%; */
  margin-right: 5px;
  margin-left: 10px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
