import React, { useContext, useMemo, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import context from '../../context';
import { GlobalContext } from '../../../../App';

import { putData, getData } from '../../API';

const UpdateRegionsGroup = React.memo(({ selectedGroup, ...props }) => {
  const { state, dispatch } = useContext(context);
  const { token } = useContext(GlobalContext);
  const [groupName, setGroupName] = useState({
    value: selectedGroup.name,
    rows: 1,
    minRows: 1,
    maxRows: 10
  });
  const [intersectionsAllowed, setIntersectionsAllowed] = useState(
    selectedGroup.intersection_allowed
  );

  useEffect(() => {
    setGroupName({ ...groupName, value: selectedGroup.name });
    setIntersectionsAllowed(selectedGroup.intersection_allowed);
  }, [selectedGroup]);

  useEffect(() => {
    if (groupName.value === '' || !props.OKPressed) return;
    makePutRequest();
  }, [props.OKPressed]);

  const makePutRequest = async () => {
    const url = `https://outdoor-maps.getshopster.net/regions/update_region_group/${selectedGroup.id}/`;
    const data = {
      name: groupName.value,
      intersection_allowed: intersectionsAllowed
    };
    const response = await putData(token, url, data, dispatch);
    if (response.success) {
      dispatch({
        type: 'resetGroupToEdit'
      });
      getData(
        token,
        `https://outdoor-maps.getshopster.net/regions/region_groups/`,
        'getRegionGroups',
        dispatch
      );
    } else {
      console.log(response.error);
    }
  };

  const onNameChange = event => {
    const textareaLineHeight = 18;
    const { minRows, maxRows } = groupName;

    const previousRows = event.target.rows;
    event.target.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }

    setGroupName({
      ...groupName,
      value: event.target.value,
      rows: currentRows < maxRows ? currentRows : maxRows
    });
  };

  const onIntersectionsAllowedChange = e => {
    setIntersectionsAllowed(e.target.checked);
  };

  return selectedGroup ? (
    <Container>
      <Label>
        Regions Group Name
        <TextArea
          type={'text'}
          autoFocus
          value={groupName.value}
          rows={groupName.rows}
          onChange={onNameChange}
        ></TextArea>
      </Label>
      <Label direction={'row'}>
        <Input
          type={'checkbox'}
          checked={intersectionsAllowed}
          onChange={onIntersectionsAllowedChange}
        ></Input>
        Intersections Allowed
      </Label>
    </Container>
  ) : null;
});

export default UpdateRegionsGroup;

const appearence = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${appearence} 0.3s ease-in-out;
`;

const Input = React.memo(styled.input.attrs(({ type, ...props }) => {
  let marginR = 0;
  let width = '100%';
  if (type === 'checkbox') {
    marginR = '10px';
    width = 'auto';
  }
  return {
    marginR,
    width
  };
})`
  padding: 5px;
  padding-left: 0;
  margin: 0;
  margin-right: ${p => p.marginR};
  font-size: 14px;
  display: flex;
  flex-direction: column;
  width: ${p => p.width} !important;
  min-height: 30px;
  border: none !important;
  border-bottom: 1px solid #1e90ff !important;
  background-color: transparent !important;
  :focus {
    outline: none !important;
  }
`);

const TextArea = React.memo(styled.textarea.attrs(({ type, ...props }) => {
  let marginR = 0;
  let width = '100%';
  if (type === 'checkbox') {
    marginR = '10px';
    width = 'auto';
  }
  return {
    marginR,
    width
  };
})`
  line-height: 18px;
  padding: 5px;
  padding-left: 0;
  margin: 0;
  margin-right: ${p => p.marginR};
  font-size: 14px;
  display: flex;
  flex-direction: column;
  width: ${p => p.width} !important;
  min-height: 30px;
  border: none !important;
  border-bottom: 1px solid #1e90ff !important;
  background-color: transparent !important;
  :focus {
    outline: none !important;
  }
`);

const Label = React.memo(styled.label.attrs(props => {
  return {
    direction: !props.direction ? 'column' : props.direction,
    align: !props.direction ? 'flex-start' : 'center',
    cursor: !props.direction ? 'auto' : 'pointer'
  };
})`
  cursor: ${p => p.cursor};
  margin: 15px 0;
  font-size: 14px;
  color: #1e90ff;
  display: flex;
  flex-direction: ${p => p.direction};
  align-items: ${p => p.align};
  width: 100%;
  /* border: 1px solid #777; */
  border-radius: 10px;
  user-select: none;
`);
