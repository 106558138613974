import React from 'react';
import styled from 'styled-components/macro';
import eyeImage from '../img/eye.svg';

const EyeIcon = React.memo(({ marginLeft = 10, ...props }) => {
  return <Eye isVisible={props.isVisible} marginLeft={marginLeft} onClick={props.onClick} />;
});

export default EyeIcon;

const Eye = styled.div`
  opacity: ${p => (p.isVisible ? 1 : 0.3)};
  height: 20px;
  width: 20px;
  background: url(${eyeImage}) center no-repeat;
  background-size: 80%;
  border: 2px solid #777;
  /* border-radius: 50%; */
  margin-right: 5px;
  margin-left: ${p => p.marginLeft + 'px'};
  flex-shrink: 0;
`;
