import React, { useContext, useMemo } from 'react';
import context from '../context';
import styled from 'styled-components/macro';
import Layer from './layer';

const Container = styled.div`
  box-sizing: border-box;
  /* padding: 10px 0;
  padding-top: 0; */
  width: 300px;
  flex-shrink: 0;
  height: calc(100%);
  background-color: #eee;
  display: flex;
  flex-direction: column;
  /* display: grid;
  grid-template-rows: auto auto 30px;
  grid-gap: 10px; */
`;

const LayersControl = React.memo(({ ...props }) => {
  const { state, dispatch } = useContext(context);
  const { layers } = state;

  const onClick = e => {
    dispatch({
      type: 'onTestClick',
      payload: true
    });
  };

  const layersList = useMemo(() => {
    return layers.map(layer => {
      return <Layer layer={layer} key={`${layer.name}-${layer.id}`} />;
    });
  }, [layers]);

  return useMemo(() => <Container>{layersList}</Container>, [layers]);
});

export default LayersControl;
