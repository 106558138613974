const host = { adminService: 'https://admin.focustech.xyz', mapService: 'https://map-service.focustech.xyz' };

export const urls = {
    AUTH_URL: `${host.adminService}/sso/ajax-token`,
    LOCATIONS_URL: `${host.mapService}/api/locations/`,
    LOCATIONS_GEOMAPPING_URL: `${host.mapService}/api/locations-geomapping/`,
    PLANS_URL: `${host.mapService}/api/plans/`,
    // LAYERS_URL: `${host.mapService}/api/layers/`,
    // GEO_URL: `http://localhost:3006/geo-mapping/`,
};
