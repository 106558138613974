import React, { useContext, useMemo } from 'react';
import context from '../context';
import styled from 'styled-components/macro';
import ProjectLocationsList from './pl_list';
import LocationDetails from './location_details';
import Footer from './control_panel_footer';

const Container = styled.div`
    box-sizing: border-box;
    padding: 0 10px;
    padding-bottom: 20px;
    width: 300px;
    flex-shrink: 0;
    height: calc(100%);
    background-color: #eee;
    display: grid;
    grid-template-rows: auto auto 30px;
    grid-gap: 10px;
`;

const OutdoorControlPanel = React.memo(() => {
    const { state, dispatch } = useContext(context);
    const { innerData, selectedSpaces, showIPoints, projectLocationsData } = state;

    const onTargetClick = item => e => {
        dispatch({
            type: 'aimOnPoint',
            center: { lat: item.latitude, lng: item.longitude }
        });
    };

    return useMemo(
        () => (
            <Container>
                <ProjectLocationsList />
                <LocationDetails />
                <Footer />
            </Container>
        ),
        [innerData, selectedSpaces, showIPoints]
    );
});

export default OutdoorControlPanel;
