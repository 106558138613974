import React, { useRef, useEffect, useContext, useMemo, useState } from 'react';
import DrawControl from './draw_control_1';
import {
  Map,
  TileLayer,
  Marker,
  Popup,
  LayerGroup,
  LayersControl,
  AttributionControl,
  FeatureGroup,
  Pane
} from 'react-leaflet';
import MarkerClusterGroup from './react-leaflet-markercluster';
import L from 'leaflet';
import { Portal } from 'react-leaflet-portal';
import context from '../context';
import { GlobalContext } from '../../../App';

import '../leaflet/leaflet.css';
import '../style/markercluster-styles.css';
import styled from 'styled-components/macro';
import ZoomWorldControl from './zoom-world-control';
import EditModal from './modals/edit_modal';
import PanePl from './panes/pane_pl';
import PaneRegionGroups from './panes/pane_region_groups';
import PaneMalls from './panes/pane_malls';

const adSpaceIcon = new L.Icon({
  iconUrl: require('../img/pin.svg'),
  iconRetinaUrl: require('../img/pin.svg'),
  iconAnchor: [10, 30],
  popupAnchor: [0, 0],
  shadowUrl: require('../img/pin.png'),
  shadowSize: new L.Point(50, 20),
  shadowAnchor: new L.Point(10, 15),
  iconSize: new L.Point(20, 30)
});

const iPointIcon = new L.Icon({
  iconUrl: require('../img/pin_blue.svg'),
  iconRetinaUrl: require('../img/pin_blue.svg'),
  iconAnchor: [5, 15],
  popupAnchor: [0, 0],
  // shadowUrl: require('.../img/pin.png'),
  // shadowSize: new L.Point(50, 20),
  // shadowAnchor: new L.Point(10, 15),
  iconSize: new L.Point(10, 15)
});

const CursorCoords = styled.div`
  padding: 5px;
  background-color: whitesmoke;
  border: 1px solid #777;
  border-radius: 5px;
  font-size: 14px;
`;

const OutdoorMap = React.memo(() => {
  const { state, dispatch } = useContext(context);
  const { maps } = useContext(GlobalContext);
  const {
    polygonToCreate,
    isPickCursorCoords,
    innerData,
    mapCenter,
    initialZoom,
    currentZoom,
    layers
  } = state;

  const mapRef = useRef(null);
  const featureRef = useRef(null);

  useEffect(() => {
    // if (featureRef && featureRef.current) {
    //   featureRef.current.leafletElement.pm.addControls({
    //     position: 'topright',
    //     drawCircle: false
    //   });
    // }

    return () => {
      const center = mapRef.current.leafletElement.getCenter();
      const currentZoom = mapRef.current.leafletElement._zoom;
      dispatch({
        type: 'handleSaveCenter',
        payload: { center }
      });
      dispatch({
        type: 'setInitialZoom',
        payload: { currentZoom }
      });
    };
  }, [mapRef.current, featureRef]);

  const renderMarkers = (spaces, icon) => {
    if (spaces) {
      const markersCoord = new Set(
        spaces.map((item, i) => {
          return { lat: item.latitude, lng: item.longitude };
        })
      );

      return Array.from(markersCoord).map((item, i) => {
        return (
          <Marker position={{ lat: item.lat, lng: item.lng }} key={`marker-${i}`} icon={icon}>
            <Popup>{`${item.lat} ${item.lng}`}</Popup>
          </Marker>
        );
      });
    }
  };

  const createClusterCustomIcon = cluster => {
    const childCount = cluster.getChildCount();
    let c = ' custom-marker-cluster-';
    if (childCount < 10) {
      c += 'small';
    } else if (childCount < 100) {
      c += 'medium';
    } else {
      c += 'large';
    }

    return new L.divIcon({
      html: '<div><span>' + childCount + '</span></div>',
      className: 'custom-marker-cluster' + c,
      iconSize: new L.Point(40, 40)
    });
  };

  const createSquareClusterCustomIcon = cluster => {
    const childCount = cluster.getChildCount();
    let c = ' custom-marker-cluster-';
    if (childCount < 10) {
      c += 'small';
    } else if (childCount < 100) {
      c += 'medium';
    } else {
      c += 'large';
    }

    return new L.divIcon({
      html: '<div><span>' + childCount + '</span></div>',
      className: 'custom-marker-cluster-square' + c,
      iconSize: new L.Point(40, 40)
    });
  };

  const onZoomWorldClick = () => {
    const map = mapRef.current.leafletElement;
    map.setZoom(2);
  };

  const onMapMouseMove = e => {
    if (!isPickCursorCoords) return;
    const cursorCoords = {
      lat: e.latlng.lat.toFixed(5),
      lng: e.latlng.lng.toFixed(5)
    };
    dispatch({
      type: 'setCursorCoords',
      payload: { cursorCoords }
    });
  };

  const onMapClick = e => {
    // if (!allowMapClick || !polygonToCreate) return;
    // if (
    //   polygonToCreate &&
    //   polygonToCreate.mode !== 'create' &&
    //   e.originalEvent.target.localName !== 'path'
    // ) {
    //   dispatch({
    //     type: 'resetPolygonToCreate'
    //   });
    // }
  };

  const center = [mapCenter.lat, mapCenter.lng];
  const map = mapRef;

  const renderMaps = useMemo(
    () =>
      maps.map((item, i) => {
        const checked = item.baseLayer.checked ? item.baseLayer.checked : false;
        return (
          <LayersControl.BaseLayer
            name={item.baseLayer.name}
            checked={checked}
            key={`LayersControl.BaseLayer-${i}`}
          >
            <TileLayer attributionPrefix={false} minZoom={0} maxZoom={20} {...item.tileLayer} />
          </LayersControl.BaseLayer>
        );
      }),
    [maps]
  );

  const getZIndex = layerType => {
    if (layers.filter(layer => layer.type === layerType)[0].active) {
      return 349;
    } else {
      return layers.filter(layer => layer.type === layerType)[0].id + 300;
    }
  };

  const onZoom = () => {
    const currentZoom = mapRef.current.leafletElement._zoom;
    handleBoundsChange();
    dispatch({
      type: 'handleZoomChange',
      payload: { currentZoom }
    });
  };

  const handleMoveend = e => {
    handleBoundsChange();
  };

  const handleBoundsChange = () => {
    const currentBounds = mapRef.current.leafletElement.getBounds();
    // console.log(bounds.contains(L.latLng(40.774, -74.125)));
    dispatch({
      type: 'handleBoundsChange',
      payload: { currentBounds }
    });
  };

  try {
    return (
      center &&
      maps.length !== 0 && (
        <Map
          center={mapCenter}
          zoom={initialZoom}
          maxZoom={18}
          ref={mapRef}
          onZoom={onZoom}
          //   attributionControl={true}
          // onMouseMove={onMapMouseMove}
          // onMapMouseMove={}
          // onClick={onMapClick}
          onMoveend={handleMoveend}
        >
          {polygonToCreate ? <DrawControl polygonToCreate={polygonToCreate} /> : null}

          <PanePl
            zIndex={getZIndex('project_locations')}
            mapRef={mapRef}
            currentZoom={currentZoom}
          />
          <PaneRegionGroups
            zIndex={getZIndex('region_groups')}
            mapRef={mapRef}
            currentZoom={currentZoom}
          />
          <PaneMalls zIndex={getZIndex('malls')} mapRef={mapRef} currentZoom={currentZoom} />

          {/* <AttributionControl position="bottomright" prefix="" /> */}

          <Portal position="topleft">
            <ZoomWorldControl onZoomWorldClick={onZoomWorldClick}>2</ZoomWorldControl>
          </Portal>
          <LayersControl position="topleft">{renderMaps}</LayersControl>

          <Portal position="bottomleft">
            {/* <CursorCoords>
                {`Cursor coordinates: lat: ${cursorCoords.lat}  lng: ${cursorCoords.lng}`}
              </CursorCoords> */}
          </Portal>
        </Map>
      )
    );
  } catch (error) {
    console.log(error);

    return null;
  }
});

export default OutdoorMap;
